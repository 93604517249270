import StyledContainer from "@GlobalComponents/StyledContainer";
import { colors } from "@theme/Themes";
import styled from "styled-components";

export const Container = styled(StyledContainer)`
  .MuiDivider-root {
    margin: 16px 0;

    small {
      color: ${colors.textSecondary};
    }
  }

  .cta-button {
    margin-top: 8px;
  }
`;

export const ButtonContainer = styled(StyledContainer)`
  gap: 16px;
`;
