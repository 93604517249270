import * as Styled from "components/Layout/Layout.styles";

import { environment, isTaoEnabled } from "@utils/constants";

import Footer from "components/Footer/Footer";
import { Outlet } from "react-router-dom";
import Providers from "@providers/Providers";
import { ReactNode } from "react";
import TabInitialRouteWrapper from "@component/TabInitialRouteWrapper/TabInitialRouteWrapper";
import VendorFooter from "@vendor/components/VendorFooter/VendorFooter";
import VendorTopBar from "@vendor/components/VendorTopBar/VendorTopBar";
import WrongCheck from "components/WrongCheck/WrongCheck";

const { isWebTab } = environment;

const InitialRouteWrapper = ({ children }: { children?: ReactNode }) =>
  isWebTab ? (
    <TabInitialRouteWrapper>{children}</TabInitialRouteWrapper>
  ) : (
    <>{children}</>
  );

function Layout(): React.ReactElement {
  return (
    <Providers>
      <InitialRouteWrapper>
        <Styled.Wrapper
          id="layout"
          className={isTaoEnabled ? "tao-session" : ""}
        >
          {!isTaoEnabled && <VendorTopBar />}
          <WrongCheck />
          <Styled.InnerWrapper>
            <Outlet />
          </Styled.InnerWrapper>
          <Footer>
            <VendorFooter />
          </Footer>
        </Styled.Wrapper>
      </InitialRouteWrapper>
    </Providers>
  );
}

export default Layout;
