import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { colors, fonts } from "@theme/Themes";

import StyledContainer from "@GlobalComponents/StyledContainer";
import styled from "styled-components";

export const Container = styled(StyledContainer)`
  margin: 16px 0;
  gap: 10px;
  position: relative;

  &.error {
    .MuiButtonBase-root,
    .MuiInputBase-root {
      border: 1px solid ${colors.error} !important;
      overflow: hidden;
    }
  }

  .no-tip-message {
    color: ${colors.error};
    font-size: 14px;
  }

  .tips-title {
    color: ${colors.text};
    font-size: 1.8rem;
    margin-bottom: 8px;

    > * {
      color: ${colors.text};
    }
  }

  @keyframes errorAnimation {
    0%,
    50%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }
  }
`;

export const ToggleList = styled(ToggleButtonGroup)`
  align-items: stretch;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
`;

export const TipButton = styled(ToggleButton)`
  &.overwrite {
    border: 1px solid ${colors.borderLight} !important;
    background-color: ${colors.paper};
    border-radius: 6px !important;
    color: ${colors.text} !important;
    display: flex;
    flex-direction: column;
    flex: 100% 1 1;
    gap: 3px;
    padding: 9px 8px;
    text-transform: none;

    strong {
      font-size: 1.6rem;
      line-height: 1.6rem;

      &.smaller {
        font-size: 1.3rem;
      }
    }

    span {
      font-family: ${fonts.textFont};
      font-size: 1.3rem;
      font-weight: 400;
      line-height: 1.3rem;
    }

    &:hover {
      background-color: ${colors.paper};
      border-color: ${colors.borderDark};
    }

    &.Mui-selected {
      background-color: ${colors.paper};
      border: 2px solid ${colors.text} !important;
      pointer-events: none;
    }
  }
`;
