import React from "react";
import FullScreenState from "../FullScreenState/FullScreenState";
import ErrorState, { ErrorStateProps } from "./ErrorState";

const FullScreenErrorState = (props: ErrorStateProps) => {
  return (
    <FullScreenState>
      <ErrorState {...props} />
    </FullScreenState>
  );
};

export default FullScreenErrorState;
