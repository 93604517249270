/* eslint-disable import/no-anonymous-default-export */

import * as Styled from "./PhoneNumber.style";

import { useCallback, useState } from "react";

import Button from "@component/Button/Button";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PhoneAdBanner from "@vendor/components/VendorAdBanners/PhoneAdBanner";
import USAImg from "assets/us-flag.png";
import { addBreadcrumb } from "@sentry/react";
import { useAuth } from "@context/AuthContext";
import { useNavigate } from "react-router-dom";
import { useTab } from "@context/TabContext";

const phoneRegex: RegExp = /^\+1 \(\d{3}\) \d{3}-\d{4}/;

function PhoneNumber() {
  const [userPhone, setUserPhone] = useState<string | undefined>();
  const [isValid, setIsValid] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean | undefined>();

  const { getWebTabPinCode, webTabSession, setWebTabSession } = useAuth();

  const navigate = useNavigate();

  const { placeCode } = useTab();

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setUserPhone(event.currentTarget.value);
      setIsValid(phoneRegex.test(event.currentTarget.value));
    },
    []
  );

  const handleSubmitPhoneNumber = useCallback(async () => {
    const number = userPhone?.replace(/\D/g, "").slice(1);

    if (number) {
      addBreadcrumb({
        level: "info",
        category: "user_action",
        message: `Get WebTab PinCode at Place ${placeCode}`,
      });

      setIsLoading(true);
      setError(undefined);
      const response = await getWebTabPinCode(number);

      if (response === "failed") {
        setError("An error ocurred. Please try again.");
        setIsLoading(false);
        throw new Error(
          `handleSubmitPhoneNumber > Request failed: ${response}`
        );
      }

      if (webTabSession && setWebTabSession) {
        setWebTabSession({
          ...webTabSession,
          phoneNumber: number,
        });
      }

      setIsLoading(false);
      return navigate(`/venue/${placeCode}/verify`, { replace: true });
    }
  }, [
    getWebTabPinCode,
    navigate,
    placeCode,
    setWebTabSession,
    userPhone,
    webTabSession,
  ]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        void handleSubmitPhoneNumber();
      }
    },
    [handleSubmitPhoneNumber]
  );

  return (
    <Styled.Container direction="column">
      <PhoneAdBanner />
      <h3>What&apos;s Your Number?</h3>
      <span> We&apos;ll text a code to verify your phone.</span>
      <Styled.InputContainer>
        <span>
          <img src={USAImg} alt="USA Flag" width={24} />
        </span>
        <Styled.Input
          mask={[
            "+",
            "1",
            " ",
            "(",
            /\d/,
            /\d/,
            /\d/,
            ")",
            " ",
            /\d/,
            /\d/,
            /\d/,
            "-",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
          ]}
          className="form-control"
          placeholder="Phone Number"
          guide={false}
          id="web-tab-phone-number"
          onBlur={() => {}}
          onChange={(e) => handleOnChange(e)}
          onKeyDown={handleKeyDown}
          value={userPhone}
        />
      </Styled.InputContainer>
      {error && <Styled.ErrorMessage>{error}</Styled.ErrorMessage>}
      <Button
        color="secondary"
        disabled={!isValid}
        isLoading={isLoading}
        text="Next"
        onClick={() => {
          void handleSubmitPhoneNumber();
        }}
      />

      <Styled.Help direction="column">
        <InfoOutlinedIcon fontSize="large" />
        <p>
          <strong>Not sure what this is?</strong> — Head to our explainer page
          to find out what a web tab link is.
        </p>
        <a href="/" rel="noreferrer" target="_blank">
          Learn More
        </a>
      </Styled.Help>
    </Styled.Container>
  );
}

export default PhoneNumber;
