import { Alert, AlertTitle } from "@mui/material";

import StyledContainer from "@GlobalComponents/StyledContainer";
import TextField from "components/Inputs/TextField/TextField";
import { colors } from "@theme/Themes";
import styled from "styled-components";

interface EmailContainerProps {
  isWebTab?: boolean;
}

export const Container = styled(StyledContainer)<EmailContainerProps>`
  margin: ${({ isWebTab }) => isWebTab && "0 0 32px 0"};
`;

export const Input = styled(TextField)``;

export const SuccessBox = styled(Alert)`
  &.overwrite {
    margin-bottom: 16px;

    p {
      color: ${colors.text};
      font-size: 1.2rem;
      margin: 0;
    }
  }
`;

export const SuccessTitle = styled(AlertTitle)`
  color: ${colors.text};
`;
