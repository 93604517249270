import {
  PaymentCard,
  Container as PaymentMethodContainer,
  PaymentMethodItem,
  PaymentMethodLabel,
  PaymentMethodList,
} from "@component/PaymentMethodList/PaymentMethod.styles";

import Button from "@component/Button/Button";
import StyledContainer from "@GlobalComponents/StyledContainer";
import styled from "styled-components";

export const Container = styled(StyledContainer)`
  gap: 16px;

  &.tab-overdue {
    margin: 32px 0 0;

    h3 {
      margin: 0;
    }

    ul {
      margin: 0;
    }
  }
`;

export const List = styled(PaymentMethodList)`
  margin: 32px 0 0;
`;

export const ListItem = styled(PaymentMethodItem)``;

export const Card = styled(PaymentCard)``;

export const CardContainer = styled(PaymentMethodContainer)``;

export const Label = styled(PaymentMethodLabel)``;

export const AddNewPaymentButton = styled(Button)`
  padding: 9px 10px !important;

  div {
    align-items: center;
    display: flex;
    gap: 8px;
    width: 100%;

    .MuiSvgIcon-root {
      height: 28px;
      width: 28px;
    }
  }
`;
