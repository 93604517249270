import Button from "@component/Button/Button";
import StyledContainer from "@GlobalComponents/StyledContainer";
import { colors } from "@theme/Themes";
import styled from "styled-components";

export const MainContainer = styled(StyledContainer)`
  color: ${colors.text};
  gap: 16px;
  margin: 32px 0 0;

  h2#payment-method {
    font-size: 1.8rem;
    margin: 0px;

    a {
      color: inherit;
      pointer-events: none;
    }
  }
`;

export const Container = styled(StyledContainer)`
  background-color: ${colors.paper};
  border-radius: 6px;
  gap: 16px;
  margin: 0 0 32px 0;
  padding: 14px;
`;

export const ChangeButton = styled(Button)`
  &.overwrite {
    margin-left: auto;
    max-width: 80px;
  }
`;
