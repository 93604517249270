import { useCallback, useState } from "react";

import StyledContainer from "@GlobalComponents/StyledContainer";
import styled from "styled-components";

const Container = styled(StyledContainer)`
  gap: 12px;
  margin: 32px 0;
`;

function TabPaytronixForm() {
  const [placeCode, setPlaceCode] = useState<string | undefined>("37UTRKXHZY");

  const url = window.location.host;

  const handlePlaceCode = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setPlaceCode(event.currentTarget.value);
    },
    []
  );

  /* document
    .getElementById("submitButton")
    ?.addEventListener("click", function () {
      const formData = {
        token: document.getElementById("grantToken"),
        firstName: document.getElementById("firstName"),
        lastName: document.getElementById("lastName"),
        phone: document.getElementById("phone"),
        email: document.getElementById("email"),
        memberId: document.getElementById("memberId"),
        placeCode: document.getElementById("placeCode"),
      };

      const xhr = new XMLHttpRequest();
      xhr.open(
        "POST",
        "https://aceae8f4.rooam-rooamtab-react-staging.pages.dev/venue/37UTRKXHZY",
        true
      );
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.send(JSON.stringify(formData));
    }); */

  return (
    <Container direction="column">
      <h2>Paytronix - Login Form</h2>

      <form
        id="submitForm"
        method="POST"
        action={`https://${url}/venue/${placeCode}`}
      >
        <input
          type="hidden"
          name="token"
          defaultValue={process.env.REACT_APP_PAYTRONIX_API_TOKEN}
        />
        <label htmlFor="firstName">First Name:</label>
        <input
          type="text"
          id="firstName"
          name="firstName"
          defaultValue="Rob"
          required
        />
        <br />
        <br />
        <label htmlFor="lastName">Last Name:</label>
        <input
          type="text"
          id="lastName"
          name="lastName"
          defaultValue="Arroyo"
          required
        />
        <br />
        <br />
        <label htmlFor="phone">Phone:</label>
        <input
          type="tel"
          id="phone"
          name="phone"
          defaultValue="2221111111"
          required
        />
        <br />
        <br />
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          name="email"
          defaultValue="rob.arroyo@rooam.co"
          required
        />
        <br />
        <br />
        <label htmlFor="memberId">Member ID:</label>
        <input
          type="text"
          id="memberId"
          name="memberId"
          defaultValue="1300342431"
          required
        />
        <br />
        <br />
        <label htmlFor="placeCode">Place Code:</label>
        <input
          type="text"
          id="placeCode"
          name="placeCode"
          defaultValue="37UTRKXHZY"
          required
          onChange={handlePlaceCode}
        />
        <br />
        <br />
        <label htmlFor="grantToken">Grant token:</label>
        <input type="text" id="grantToken" name="token" required />
        <br />
        <br />
        <input type="submit" value="Submit" id="submitButton"></input>
      </form>
    </Container>
  );
}

export default TabPaytronixForm;
