import * as Styled from "./VoidedTab.styles";

import VoidedIcon from "@component/Icons/VoidedIcon";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import usePageTitle from "@hooks/usePageTitle";
import { useTab } from "@context/TabContext";

function VoidedTab() {
  usePageTitle("Tab Voided");

  const navigate = useNavigate();
  const { placeCode } = useTab();

  const handleOpenTab = useCallback(() => {
    navigate(`/venue/${placeCode}/tab`, {
      replace: true,
    });
  }, [navigate, placeCode]);

  return (
    <Styled.Container direction="column">
      <Styled.ContentContainer direction="column">
        <VoidedIcon />
        <Styled.Title>Tab Voided</Styled.Title>
        <span>No payment was made.</span>
      </Styled.ContentContainer>
      <Styled.OpenTabButton
        color="secondary"
        className="overwrite"
        text="Open Another Tab"
        onClick={handleOpenTab}
      />
    </Styled.Container>
  );
}

export default VoidedTab;
