import getBoolean from "@utils/getBoolean";

export const environment = {
  baseUrl: process.env.REACT_APP_API_URL,
  originUrl: process.env.REACT_APP_PLACE_ORIGIN,
  refererUrl: process.env.REACT_APP_PLACE_REFERER,
  sentry: {
    dsn: process.env.REACT_APP_SENTRY_DSN,
    tracesSampleRate: process.env.REACT_APP_SENTRY_SAMPLERATE,
    release: process.env.REACT_APP_SENTRY_VERSION,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    replays: {
      sessionSampleRate:
        process.env.REACT_APP_SENTRY_REPLAYS_SESSION_SAMPLERATE,
      onErrorSampleRate:
        process.env.REACT_APP_SENTRY_REPLAYS_ONERROR_SAMPLERATE,
    },
  },
  payments: {
    expressCheckout: {
      disabledAmexPwp: getBoolean(
        process.env.REACT_APP_PAYMENTS_DISABLE_EXPRESS_CHECKOUT_AMEX_PWP
      ),
    },
    creditCard: {
      disabled: getBoolean(process.env.REACT_APP_PAYMENTS_DISABLE_CREDIT_CARD),
      disabledWarning: getBoolean(
        process.env.REACT_APP_PAYMENTS_DISABLE_CREDIT_CARD_DISABLED_WARNING
      ),
    },
  },
  viewMenuEnabled: process.env.REACT_APP_ENABLE_VIEW_MENU,
  isWebTab: process.env.REACT_APP_IS_WEBTAB === "true",
};

export const queryKeys = {
  checkFeed: "checkFeed",
  placeData: "placeData",
  upserveCheckFeed: "upserveCheckFeed",
  checkAtTableFeed: "checkAtTableFeed",
  paidAmount: "paidAmount",
  paytronixCheckFeed: "paytronixCheckFeed",
  tabFeed: "tabFeed",
};

export const isTaoEnabled = Boolean(sessionStorage.getItem("taoEnabled"));

export const GA_TRACK_ID = process.env.REACT_APP_GOOGLE_TRACK_ID;

export const isDev =
  environment.sentry.environment === "staging" ||
  environment.sentry.environment === "local" ||
  process.env.NODE_ENV === "development";
export const isProd =
  environment.sentry.environment === "production" ||
  process.env.NODE_ENV === "production";
