import * as Styled from "./Order.styles";

import OrderedItem, { ItemProps } from "./OrderItem";

import { Collapse } from "@mui/material";
import { TabItemData } from "@utils/getTabFeed/getTabFeed";
import { TransitionGroup } from "react-transition-group";

interface OrderItemProps {
  items: ItemProps[] | TabItemData[];
}

function OrderList({ items }: OrderItemProps): React.ReactElement {
  return (
    <Styled.ListContainer spacing={1}>
      <TransitionGroup>
        {items.map((item, index) => (
          <Collapse key={`order-item-${index}`} role="list">
            <OrderedItem item={item} index={index} />
          </Collapse>
        ))}
      </TransitionGroup>
    </Styled.ListContainer>
  );
}

export default OrderList;
