import * as Styled from "./TabPaid.styles";

import { CircularProgress, Zoom } from "@mui/material";
import { useCallback, useEffect, useState } from "react";

import CheckIcon from "@mui/icons-material/Check";
import EmailReceipt from "@component/EmailReceipt/EmailReceipt";
import RateExperience from "@component/RateExperience/RateExperience";
import TabSummary from "@component/TabSummary/TabSummary";
import { colors } from "@theme/Themes";
import { useNavigate } from "react-router-dom";
import { useTab } from "@context/TabContext";

function TabPaid() {
  const [isLoading, setIsLoading] = useState(false);

  const { getTabNumber, feed, selectedTip } = useTab();

  const navigate = useNavigate();
  const {
    placeCode,
    receipt: receiptData,
    isOverdue,
    isReceiptLoading,
  } = useTab();

  const receipt = receiptData?.data?.receipt ?? feed?.totals;
  const receiptTip =
    receiptData?.data?.receipt.tip ?? selectedTip?.amount?.toString() ?? "0.00";
  const receiptItems = receiptData?.data?.items ?? feed?.tab?.items;

  const handleOpenTab = useCallback(async () => {
    setIsLoading(true);

    const data = await getTabNumber();

    if (data.tabNumber) {
      navigate(`/venue/${placeCode}/tab`, {
        replace: true,
      });
    }

    setIsLoading(false);
  }, [getTabNumber, navigate, placeCode]);

  useEffect(() => {
    if (isOverdue) {
      navigate(`/venue/${placeCode}/overdue`, {
        replace: true,
      });
    }
  }, [isOverdue, navigate, placeCode]);

  return (
    <>
      {receipt && receipt?.total !== "0.00" && (
        <TabSummary
          subtotal={receipt.subTotal}
          tax={receipt.tax}
          fees={receipt.fee}
          tip={receiptTip}
          total={receipt.total}
          items={receiptItems}
          collapsible={true}
        />
      )}

      {(isReceiptLoading || receipt?.total === "0.00") && (
        <Styled.ReceiptLoader>
          Getting Tab Details <CircularProgress size={20} />
        </Styled.ReceiptLoader>
      )}

      {!isReceiptLoading && !receipt && (
        <Styled.ReceiptLoader>Tab Details not available</Styled.ReceiptLoader>
      )}

      <Styled.Container direction="column">
        <Styled.CheckIconMainContainer className="row-icon" direction="column">
          <Zoom in={true}>
            <Styled.CheckIconContainer fillColor={colors.lighterBackground}>
              <CheckIcon />
            </Styled.CheckIconContainer>
          </Zoom>
          <h4 id="payment-completed">Payment Complete</h4>
        </Styled.CheckIconMainContainer>

        <Styled.OpenTabButton
          isLoading={isLoading}
          disabled={isReceiptLoading}
          color="secondary"
          className="overwrite"
          text="Open Another Tab"
          onClick={() => {
            void handleOpenTab();
          }}
        />

        <RateExperience />
        <EmailReceipt isWebTab={true} />
      </Styled.Container>
    </>
  );
}

export default TabPaid;
