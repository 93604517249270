import { QueryKey, useQuery } from "react-query";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import getTabFeed, { TabFeedData } from "@utils/getTabFeed/getTabFeed";
import getTabReceipt, { TabReceiptResponse } from "@utils/getTabReceipt";
import getTabTips, { TipProps, TipsData } from "@utils/getTabTips";
import { useLocation, useParams } from "react-router-dom";

import { Client } from "braintree-web";
import closeTab from "@utils/closeTab";
import getCurrentTip from "@utils/getCurrentTip";
import getTabBraintreeInstance from "@utils/getTabBraintreeInstance";
import openTab from "@utils/openTab";
import { queryKeys } from "@utils/constants";
import { setItem } from "@hooks/useCookies";
import { setTag } from "@sentry/react";
import { useAuth } from "./AuthContext";

interface GetTabFeedRequest {
  placeCode: string;
  tabNumber: string;
}

export const feedQueryKeyBuilder = ({
  placeCode,
  tabNumber,
}: GetTabFeedRequest) =>
  [queryKeys.tabFeed, { placeCode, tabNumber }] as QueryKey;

interface TabContextProps {
  closeOpenTab: ({ tip }: { tip: string }) => Promise<any>;
  error: unknown;
  feed: TabFeedData | undefined;
  generateTabInstance: () => Promise<void>;
  getTabNumber: () => Promise<{ tabNumber: string }>;
  isFeedLoading: boolean;
  isOverdue: boolean | undefined;
  isReceiptLoading: boolean;
  placeCode: string | undefined;
  receipt: TabReceiptResponse | undefined;
  selectedTip: TipProps | undefined;
  tabInstance: Client | undefined;
  tabNumber: string | undefined;
  tabToken: string | undefined;
  tabTotal: string | undefined;
  tips: TipsData | undefined;
  updateSelectedTip: (tip: TipProps | undefined) => void;
}

export const TabContext = createContext<TabContextProps | null>(null);

TabContext.displayName = "TabContext";

function TabProvider(props: any): React.ReactElement {
  const [tabInstance, setTabInstance] = useState<Client | undefined>();
  const [tabToken, setTabToken] = useState<string | undefined>();
  const [tabNumber, setTabNumber] = useState<string | undefined>();
  const [tips, setTips] = useState<TipsData>();
  const [selectedTip, setSelectedTip] = useState<TipProps | undefined>();
  const [tabTotal, setTabTotal] = useState<string | undefined>();
  const [enableReceiptQuery, setEnableReceiptQuery] = useState<boolean>(false);
  const [isOverdue, setIsOverdue] = useState<boolean>();
  const [receiptRetries, setReceiptRetries] = useState<number>(0);
  const [isReceiptLoading, setIsReceiptLoading] = useState<boolean>(true);

  const { webTabSession, setWebTabSession } = useAuth();
  const { placeCode } = useParams();

  const location = useLocation();
  const path = location.pathname;

  // validate path to refetch feed
  const validFeedPaths = ["/tab", "/overdue", "/complete"];
  const isValidFeedPath = validFeedPaths.some((subPath) =>
    path.includes(subPath)
  );

  // validate path to refetch receipt
  const validReceiptPaths = ["/complete"];
  const isValidReceiptPath = validReceiptPaths.some((subPath) =>
    path.includes(subPath)
  );

  const generateTabInstance = useCallback(async () => {
    try {
      const { authorization, instance } = await getTabBraintreeInstance();

      if (authorization && instance) {
        setTabInstance(instance);
        setTabToken(authorization);
      } else {
        console.error("Authorization or instance is missing.");
      }
    } catch (error) {
      console.error("Error while fetching Tab Braintree instance:", error);
    }
  }, []);

  // Get Check Data
  const feedQueryKey = useMemo(
    () =>
      feedQueryKeyBuilder({
        placeCode: placeCode ?? "",
        tabNumber: tabNumber ?? "",
      }),
    [placeCode, tabNumber]
  );

  const {
    data: feed,
    error,
    isLoading: isFeedLoading,
  } = useQuery(feedQueryKey, getTabFeed, {
    enabled: isValidFeedPath,
    refetchInterval: 10_000,
    staleTime: 10_000,
  });

  const { data: receipt, refetch: receiptRefetch } = useQuery(
    ["receiptData", `${placeCode}/complete`],
    getTabReceipt,
    {
      enabled: enableReceiptQuery ?? isValidReceiptPath,
      staleTime: Infinity,
      refetchInterval: 5_000,
      onSettled(response) {
        setReceiptRetries(receiptRetries + 1);

        if (response?.overdue?.hasOverduePayment) {
          setIsOverdue(response?.overdue?.hasOverduePayment);
          setEnableReceiptQuery(false);
          setIsReceiptLoading(false);
        }

        if (response?.data?.receipt) {
          setIsOverdue(false);
          setEnableReceiptQuery(false);
          setIsReceiptLoading(false);
        }

        if (receiptRetries >= 10) {
          setIsOverdue(false);
          setEnableReceiptQuery(false);
          setIsReceiptLoading(false);
        }
      },
    }
  );

  const getTabNumber = useCallback(async () => {
    if (placeCode) {
      const data = await openTab(placeCode);
      const _tabNumber = data?.tabNumber;

      if (_tabNumber) {
        setTabNumber(_tabNumber);

        setTag("placeCode", placeCode);
        setTag("ticketNumber", _tabNumber);

        if (webTabSession && setWebTabSession) {
          setWebTabSession({
            ...webTabSession,
            tabData: {
              ...webTabSession.tabData,
              tabNumber: _tabNumber,
            },
          });
        }
      } else {
        feed?.tab.tabNumber && setTabNumber(feed?.tab.tabNumber);
      }

      return data;
    }
  }, [feed?.tab.tabNumber, placeCode, setWebTabSession, webTabSession]);

  // Refetch the data when the URL changes to include "/complete"
  useEffect(() => {
    if (path.includes("/complete")) {
      setEnableReceiptQuery(true);
      void receiptRefetch();
    }
  }, [path, receiptRefetch]);

  const closeOpenTab = useCallback(
    async ({ tip }: { tip: string }) => {
      if (placeCode) {
        const closeAction = await closeTab({ placeCode, tip, tabNumber });

        if (closeAction) {
          setTabNumber(undefined);
        }

        return closeAction;
      }
    },
    [placeCode, tabNumber]
  );

  const getTips = useCallback(async (subtotal: string) => {
    const data = await getTabTips(subtotal);

    return setTips(data);
  }, []);

  useEffect(() => {
    if (placeCode && tabNumber && feed?.totals?.subTotal) {
      const subtotalBeforeDiscount = (
        parseFloat(feed.totals.subTotal) + parseFloat(feed.totals.discount)
      ).toFixed(2);

      void getTips(subtotalBeforeDiscount);
    }
  }, [feed?.totals, getTips, placeCode, tabNumber]);

  // set saved tip or default tip
  useEffect(() => {
    if (
      !selectedTip &&
      tips &&
      tabNumber &&
      feed &&
      feed?.tab?.items?.length > 0
    ) {
      const currentTip = getCurrentTip(tabNumber);
      const defaultTipPercentage = tips.defaultTip?.percentage ?? "0";
      const subtotal = feed.totals.subTotal ?? "0";

      if (!currentTip || (!selectedTip && currentTip.amount === 0)) {
        const tipToSet = {
          percentage: defaultTipPercentage,
          amount: parseFloat(subtotal) * (defaultTipPercentage / 100),
        };

        setSelectedTip(tipToSet);

        if (webTabSession && setWebTabSession) {
          setWebTabSession({
            ...webTabSession,
            tabData: {
              ...webTabSession?.tabData,
              tip: tipToSet,
            },
          });
        }

        return setItem(`userTip_${tabNumber}`, JSON.stringify(tipToSet), 1);
      }

      if (webTabSession && setWebTabSession) {
        setWebTabSession({
          ...webTabSession,
          tabData: {
            ...webTabSession?.tabData,
            tip: currentTip,
          },
        });
      }

      setSelectedTip(currentTip);
    }
  }, [feed, selectedTip, setWebTabSession, tabNumber, tips, webTabSession]);

  const updateSelectedTip = useCallback(
    (tip: TipProps | undefined) => {
      setSelectedTip(tip);
    },
    [setSelectedTip]
  );

  useEffect(() => {
    if (feed?.tab.items) {
      const taxesFees =
        parseFloat(feed?.totals.tax ?? "0") +
        parseFloat(feed?.totals.fee ?? "0");
      const userTip = selectedTip?.amount ?? 0;
      let total = taxesFees + userTip;
      const discount = parseFloat(feed?.totals?.discount ?? "0");

      feed?.tab.items.map((item) => {
        const itemPrice = parseFloat(item.pricePerUnit ?? "0");
        total = total + item.quantity * itemPrice - discount;

        setTabTotal(total.toFixed(2).toString());

        return total;
      });

      if (webTabSession && setWebTabSession) {
        setWebTabSession({
          ...webTabSession,
          tabData: {
            ...webTabSession?.tabData,
            tabNumber: feed.tab.tabNumber,
          },
        });
      }

      if (!tabNumber) {
        setTabNumber(feed.tab.tabNumber);
      }
    }
    // 🚨 Don't add webTabSession to the dependency array to avoid multiple re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    feed?.tab.items,
    feed?.tab.tabNumber,
    feed?.totals.fee,
    feed?.totals.tax,
    selectedTip?.amount,
    setWebTabSession,
  ]);

  const value: TabContextProps = useMemo(
    () => ({
      closeOpenTab,
      error,
      feed,
      generateTabInstance,
      getTabNumber,
      isFeedLoading,
      isOverdue,
      isReceiptLoading,
      placeCode,
      receipt,
      selectedTip,
      tabInstance,
      tabNumber,
      tabToken,
      tabTotal,
      tips,
      updateSelectedTip,
    }),
    [
      closeOpenTab,
      error,
      feed,
      generateTabInstance,
      getTabNumber,
      isFeedLoading,
      isOverdue,
      isReceiptLoading,
      placeCode,
      receipt,
      selectedTip,
      tabInstance,
      tabNumber,
      tabToken,
      tabTotal,
      tips,
      updateSelectedTip,
    ]
  );

  return (
    <TabContext.Provider value={value} {...props} />
  ) as React.ReactElement;
}

export default TabProvider;

// Hook to consume TabProvider
export function useTab(): TabContextProps {
  const context = useContext(TabContext);

  if (context === undefined) {
    throw new Error("useTab must be used within a TabProvider");
  }

  if (context === null) {
    throw new Error("TabProvider supplied null context");
  }

  return context;
}
