import TabPaid from "@component/TabPaid/TabPaid";
import usePageTitle from "@hooks/usePageTitle";

function TabComplete() {
  usePageTitle("Payment Complete");

  return <TabPaid />;
}

export default TabComplete;
