import { environment } from "./constants";

interface AddPaymentMethodRequest {
  id: string;
  paymentType: string;
}

async function updateTabPaymentMethod({
  id,
  paymentType,
}: AddPaymentMethodRequest): Promise<any> {
  const xRooamSession = localStorage.getItem("x-rooam-session");

  const headers = new Headers();
  headers.append("content-type", "application/json");
  xRooamSession && headers.append("x-rooam-session", xRooamSession);
  environment.originUrl && headers.append("origin", environment.originUrl);
  environment.refererUrl && headers.append("referer", environment.refererUrl);

  const body = JSON.stringify({
    paymentType,
  });

  try {
    const response = await fetch(`${environment.baseUrl}/payments/${id}`, {
      method: "PUT",
      headers,
      body,
    });

    if (!response.ok) {
      throw new Error(`Request failed with status ${response.status}`);
    }

    return;
  } catch (error) {
    console.error("Error updating default Payment Method to Braintree", error);
    throw error;
  }
}

export default updateTabPaymentMethod;
