import { colors } from "@theme/Themes";
import styled from "styled-components";

export const Container = styled.footer`
  font-size: 1.1rem;
  line-height: 1.7rem;
  padding: 0 0 30px;
  text-align: center;

  a {
    color: inherit;

    &:focus {
      text-decoration: underline;
    }

    &:hover {
      color: ${colors.textDisabled};
    }
  }

  .text-lighter {
    color: ${colors.textDisabled};

    a {
      color: inherit;
      text-decoration: underline;
    }
  }
`;
