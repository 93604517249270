import Button from "@component/Button/Button";
import StyledContainer from "@GlobalComponents/StyledContainer";
import styled from "styled-components";

export const Logo = styled.a`
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 8px;
  margin: 0 auto;
  max-width: 320px;
  width: 100%;

  > div {
    padding-top: 0;
  }

  img {
    width: 100%;
  }
`;

export const HelpButton = styled(Button)`
  &.MuiButtonBase-root {
    align-items: center;
    display: flex;
    gap: 8px;
    justify-content: center;
    margin: 16px auto;
    max-width: 160px;
  }
`;

export const AppButtonsContainer = styled(StyledContainer)`
  padding: 18px;
  strong {
    font-size: 1.4rem;
  }
`;

export const AppButtons = styled(StyledContainer)`
  gap: 8px;
  margin: 8px auto 64px;

  > a {
    display: flex;

    svg {
      display: flex;
      max-width: 156px;
      width: 100%;
    }
  }
`;

export const FooterButtons = styled(StyledContainer)`
  gap: 8px;
  margin: 16px auto;
  max-width: 320px;
  padding: 18px;
`;
