import * as Styled from "./TabOverdue.styles";

import { useCallback, useState } from "react";

import Button from "@component/Button/Button";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ErrorComponent from "@component/Error/Error";
import OrderList from "@component/Order/OrderList";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import TabPaymentMethodList from "@component/TabPaymentMethodList/TabPaymentMethodList";
import TabSummary from "@component/TabSummary/TabSummary";
import closeTabOverdue from "@utils/closeTabOverdue";
import { useAuth } from "@context/AuthContext";
import { useNavigate } from "react-router-dom";
import { useTab } from "@context/TabContext";

function TabOverdue() {
  const [isLoading, setIsLoading] = useState<boolean>();
  const [error, setError] = useState<string>();

  const { webTabSession } = useAuth();
  const selectedPaymentMethod = webTabSession?.selectedPaymentMethod;

  const { feed, selectedTip, tabTotal, placeCode } = useTab();

  const items = feed?.tab?.items;

  const navigate = useNavigate();

  const handleCloseTab = useCallback(async () => {
    setIsLoading(true);

    if (!selectedPaymentMethod) {
      setIsLoading(false);

      setError(
        "There's an error with your payment method. Try again with a different payment method."
      );

      throw new Error("Selected Payment Method Id is missing.");
    }

    const nextScreen = await closeTabOverdue(selectedPaymentMethod);

    if (nextScreen) {
      navigate(`/venue/${placeCode}/complete`, {
        replace: true,
      });
    }

    setIsLoading(false);
  }, [navigate, placeCode, selectedPaymentMethod]);

  return (
    <Styled.Container direction="column">
      <Styled.IconOuterContainer direction="column">
        <Styled.IconContainer>
          <PriorityHighIcon />
        </Styled.IconContainer>

        <h4>Unpaid Tab</h4>
        <p>
          Something went wrong while processing your payment. Before you can
          Rooam again you must settle the balance from your last tab.
        </p>
      </Styled.IconOuterContainer>

      <TabPaymentMethodList isOverdue={true} />
      <h3>Tab Details</h3>
      {items && <OrderList items={items} />}
      <TabSummary
        subtotal={feed?.totals?.subTotal ?? "0.00"}
        tax={feed?.totals?.tax ?? "0.00"}
        fees={feed?.totals?.fee ?? "0.00"}
        tip={selectedTip?.amount?.toFixed(2) ?? "0.00"}
        total={tabTotal ?? "0.00"}
        collapsible={false}
      />
      {error && (
        <ErrorComponent>
          <strong>Error: </strong>
          {error}
        </ErrorComponent>
      )}
      <Button
        isLoading={isLoading}
        color="secondary"
        node={
          <div>
            <CreditCardIcon />
            <span>Close Tab: ${tabTotal}</span>
          </div>
        }
        onClick={() => {
          void handleCloseTab();
        }}
      />
    </Styled.Container>
  );
}

export default TabOverdue;
