import braintree from "braintree-web";
import { colors } from "@theme/Themes";
import { isDev } from "utils/constants";

interface createHostedFieldsFormProps {
  client: any;
  setHostedFieldsInstance: (
    value: React.SetStateAction<braintree.HostedFields | undefined>
  ) => void;
}

function createHostedFieldsForm({
  client,
  setHostedFieldsInstance,
}: createHostedFieldsFormProps) {
  braintree.hostedFields.create(
    {
      client,
      styles: {
        body: {
          "background-color": `${colors.lighterBackground}`,
        },
        input: {
          "font-size": "14px",
          "background-color": `${colors.lighterBackground}`,
        },
        ".valid": {
          color: "green",
        },
        ".invalid": {
          color: colors.error,
        },
      },
      fields: {
        cardholderName: {
          selector: "#cardholder-name",
          placeholder: "Cardholder Name",
          prefill: isDev ? "John Doe Test" : undefined,
        },
        number: {
          selector: "#cc-number",
          placeholder: "Credit Card",
          prefill: isDev ? "4111 1111 1111 1111" : undefined,
        },
        cvv: {
          selector: "#cc-cvv",
          placeholder: "123",
          prefill: isDev ? "123" : undefined,
        },
        expirationDate: {
          selector: "#cc-expiration",
          placeholder: "MM / YY",
          prefill: isDev ? "12/26" : undefined,
        },
        postalCode: {
          selector: "#cc-zip",
          placeholder: "Zip Code",
          prefill: isDev ? "90210" : undefined,
        },
      },
    },
    function (err, hfInstance) {
      // We don't need FE error thrown when hosted fields are already there
      if (err && err.code !== "HOSTED_FIELDS_FIELD_DUPLICATE_IFRAME") {
        console.error(
          "Add Payment Method > Braintree Hosted Fields failure",
          err
        );
        return null;
      }

      setHostedFieldsInstance(hfInstance);
    }
  );
}

export default createHostedFieldsForm;
