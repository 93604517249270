import "App.css";
import "@theme/fonts.css";

import { CssBaseline, ThemeProvider } from "@mui/material";
import { GA_TRACK_ID, isProd } from "utils/constants";
import { QueryClient, QueryClientProvider } from "react-query";

import AuthProvider from "context/AuthContext";
import { ErrorBoundary } from "@sentry/react";
import FullScreenErrorState from "./components/Error/FullScreenErrorState";
import ProviderTreeBuilder from "context/ProviderTreeBuilder";
import React from "react";
import { ReactQueryDevtools } from "react-query/devtools";
import { install } from "ga-gtag";
import { vendorTheme } from "@theme/Themes";
import AppRoutes from "@app-routes/AppRoutes";

function App(): React.ReactElement {
  if (isProd) {
    install(GA_TRACK_ID);
  }

  const queryClient = new QueryClient();

  const ProviderTree = ProviderTreeBuilder([
    [QueryClientProvider, { client: queryClient }],
    [AuthProvider],
    [ThemeProvider, { theme: vendorTheme }],
  ]);

  return (
    <ErrorBoundary
      fallback={
        <FullScreenErrorState
          title={"Something went wrong"}
          showReloadPage={true}
        />
      }
      showDialog
    >
      <React.StrictMode>
        <ProviderTree>
          <CssBaseline enableColorScheme={true} />
          <AppRoutes />
          <ReactQueryDevtools />
        </ProviderTree>
      </React.StrictMode>
    </ErrorBoundary>
  );
}

export default App;
