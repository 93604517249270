import { environment } from "./constants";

async function getTabPaymentMethods(): Promise<any> {
  const xRooamSession = localStorage.getItem("x-rooam-session");

  const headers = new Headers();
  headers.append("content-type", "application/json");
  xRooamSession && headers.append("x-rooam-session", xRooamSession);
  environment.originUrl && headers.append("origin", environment.originUrl);
  environment.refererUrl && headers.append("referer", environment.refererUrl);

  try {
    const response = await fetch(`${environment.baseUrl}/payments`, {
      method: "GET",
      headers,
    });

    if (!response.ok) {
      throw new Error(`Request failed with status ${response.status}`);
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Error getting user's payment methods", error);
  }
}

export default getTabPaymentMethods;
