import {
  CheckIconContainer as PaymentCheckIconContainer,
  Container as PaymentContainer,
} from "@component/PaymentComplete/PaymentComplete.styles";

import Button from "@component/Button/Button";
import StyledContainer from "@GlobalComponents/StyledContainer";
import { colors } from "@theme/Themes";
import styled from "styled-components";

export const CheckIconMainContainer = styled(PaymentContainer)``;

export const CheckIconContainer = styled(PaymentCheckIconContainer)``;

export const Container = styled(StyledContainer)`
  min-height: 54vh;
`;

export const OpenTabButton = styled(Button)`
  &.overwrite {
    display: flex;
    margin: auto 0 18px;
  }
`;

export const ReceiptLoader = styled(StyledContainer)`
  background-color: ${colors.background};
  border-bottom: 1px solid ${colors.border};
  font-weight: 500;
  height: 58px;
  gap: 16px;
  position: relative;

  &:before {
    background-color: ${colors.background};
    border-bottom: 1px solid ${colors.border};
    content: " ";
    position: absolute;
    z-index: -1;
    height: calc(100% + 1px);
    width: 200%;
    left: -50%;
    top: 0%;
  }
`;
