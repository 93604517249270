import { PaymentMethodProps } from "@component/TabPaymentMethodList/TabPaymentMethodList";
import { environment } from "./constants";
import getNextScreen from "./getNextScreen";

async function closeTabOverdue(
  selectedPaymentMethod: PaymentMethodProps
): Promise<string | undefined> {
  const xRooamSession = localStorage.getItem("x-rooam-session");

  const headers = new Headers();
  headers.append("content-type", "application/json");
  environment.originUrl && headers.append("origin", environment.originUrl);
  environment.refererUrl && headers.append("referer", environment.refererUrl);
  xRooamSession && headers.append("x-rooam-session", xRooamSession);

  const body = JSON.stringify({
    paymentType: selectedPaymentMethod.type,
  });

  try {
    const response = await fetch(
      `${environment.baseUrl}/payments/${selectedPaymentMethod.id}`,
      {
        method: "PUT",
        headers,
        body,
      }
    );

    if (!response.ok) {
      throw new Error(
        `Update Payment Method request failed with status ${response.status}`
      );
    }

    const overdueResponse = await fetch(`${environment.baseUrl}/overdue`, {
      method: "POST",
      headers,
      body: JSON.stringify({}),
    });

    if (!overdueResponse.ok) {
      throw new Error(
        `Close overdue request failed with status ${response.status}`
      );
    }

    const nextScreen = await getNextScreen();

    return nextScreen;
  } catch (error) {
    console.error(error);
  }
}

export default closeTabOverdue;
