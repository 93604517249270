import { environment } from "./constants";

interface PaytronixData {
  amount: string;
  used: string;
}

async function getTabPaytronixRewardPoints(): Promise<
  PaytronixData | undefined
  > {
  const xRooamSession = localStorage.getItem("x-rooam-session");
  const headers = new Headers();
  headers.append("content-type", "application/json");
  environment.originUrl && headers.append("origin", environment.originUrl);
  xRooamSession && headers.append("X-Rooam-Session-Static", xRooamSession);

  try {
    const response = await fetch(`${environment.baseUrl}/tao`, {
      method: "GET",
      headers,
    });

    if (!response.ok) {
      throw new Error(
        `PaytronixRewardPoints request failed with status ${response.status}`
      );
    }

    const data = await response.json();

    return {
      amount: data.amount,
      used: data.used,
    };
  } catch (error) {
    console.error(error);
  }
}

export default getTabPaytronixRewardPoints;
