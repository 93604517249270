import { colors } from "@theme/Themes";
import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: ${colors.lighterBackground};
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.04);
  -webkit-box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.04);
  display: flex;
  flex-direction: column;
  font-size: "1rem";
  margin: 0 auto;
  max-width: 640px;
  min-height: 100vh;
  overflow: hidden;
  padding: 0;
  padding-top: 88px;
  position: relative;
  width: 100%;
  z-index: 1;

  &.tao-session {
    padding-top: 0 !important;
  }
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex: 100% 1 1;
  flex-direction: column;
  padding: 0 18px;

  @media only screen and (min-width: 541px) {
    padding: 0 56px;
  }
`;
