import * as Styled from "./TabLiveFeed.styles";

import { useCallback, useEffect, useState } from "react";

import Button from "@component/Button/Button";
import ComponentLoader from "@component/Loading/ComponentLoader";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import EmptyTab from "@component/EmptyTab/EmptyTab";
import OrderList from "@component/Order/OrderList";
import TabActionSheet from "@component/TabActionSheet/TabActionSheet";
import TabPaymentMethod from "@component/TabPaymentMethod/TabPaymentMethod";
import TabPaytronixRewards from "@component/TabPaytronixRewards/TabPaytronixRewards";
import TabSummary from "@component/TabSummary/TabSummary";
import TabTips from "@component/TabTips/TabTips";
import { useNavigate } from "react-router-dom";
import { useTab } from "@context/TabContext";

function TabLiveFeed() {
  const [isActionSheetOpen, setIsActionSheetOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [noTipSelected, setNoTipSelected] = useState<boolean | undefined>();
  const [appliedDiscount, setAppliedDiscount] = useState<string | undefined>();

  const navigate = useNavigate();

  const {
    closeOpenTab,
    feed,
    getTabNumber,
    isFeedLoading,
    placeCode,
    selectedTip,
    tabNumber,
    tabTotal,
  } = useTab();

  const items = feed?.tab?.items;

  useEffect(() => {
    if (!tabNumber) {
      void getTabNumber();
    }
  }, [getTabNumber, tabNumber]);

  const handleActionSheetIsOpen = useCallback(() => {
    setIsActionSheetOpen(!isActionSheetOpen);
  }, [isActionSheetOpen]);

  const handleCloseTab = useCallback(async () => {
    setIsLoading(true);
    setNoTipSelected(false);

    if (!selectedTip) {
      setIsLoading(false);
      return setNoTipSelected(true);
    }

    handleActionSheetIsOpen();

    const nextScreen = await closeOpenTab({
      tip: selectedTip?.amount?.toFixed(2) ?? "0",
    });

    if (nextScreen) {
      navigate(`/venue/${placeCode}/${nextScreen.next.toLowerCase()}`, {
        replace: true,
      });
    }

    setIsLoading(false);
  }, [closeOpenTab, handleActionSheetIsOpen, navigate, placeCode, selectedTip]);

  useEffect(() => {
    if (feed?.totals.discount !== "0.00") {
      setAppliedDiscount(feed?.totals.discount);
    }
  }, [feed?.totals.discount]);

  const handleDiscountApplied = useCallback((discountToApply: string) => {
    setAppliedDiscount(discountToApply);
  }, []);

  if (!feed?.open || isFeedLoading || (!placeCode && !tabNumber)) {
    return <ComponentLoader />;
  }

  if (items?.length === 0) {
    return <EmptyTab placeCode={placeCode} tabNumber={tabNumber} />;
  }

  return (
    <Styled.Container direction="column">
      <Styled.Title>
        <strong>{feed?.tab?.tabNumber}</strong> <small>Tab Number</small>
      </Styled.Title>
      {items && <OrderList items={items} />}

      <TabTips noTipSelected={noTipSelected} />

      <TabPaytronixRewards onDiscountApplied={handleDiscountApplied} />

      <TabSummary
        discount={appliedDiscount}
        subtotal={feed?.totals?.subTotal}
        tax={feed?.totals?.tax}
        fees={feed?.totals?.fee}
        tip={selectedTip?.amount?.toFixed(2) ?? "0.00"}
        total={tabTotal ?? "0.00"}
        collapsible={false}
      />

      <TabPaymentMethod />

      {tabTotal && (
        <Button
          isLoading={isLoading}
          color="secondary"
          node={
            <div>
              <CreditCardIcon />
              <span>Close Tab: ${tabTotal}</span>
            </div>
          }
          onClick={() => {
            void handleActionSheetIsOpen();
          }}
        />
      )}
      <TabActionSheet
        isActionSheetOpen={isActionSheetOpen}
        onActionSheetOpen={handleActionSheetIsOpen}
        onPerformAction={() => {
          void handleCloseTab();
        }}
        title="Ready to Go?"
        message="Make sure to check your items and your tip before closing your tab. When you are ready, tap 'Confirm' to close you tab."
        actionText="Confirm"
        isActionLoading={isLoading}
      />
    </Styled.Container>
  );
}

export default TabLiveFeed;
