import React, { useEffect } from "react";

import { QueryClient } from "react-query";
import VoidedTab from "@component/VoidedTab/VoidedTab";
import usePageTitle from "@hooks/usePageTitle";

interface VoidedProps {
  queryClient: QueryClient;
}

function Voided({ queryClient }: VoidedProps) {
  usePageTitle("Tab Voided");

  useEffect(() => {
    queryClient.removeQueries("tabFeed");
  }, [queryClient]);

  return <VoidedTab />;
}

export default Voided;
