import braintree, { Client } from "braintree-web";
import { useCallback, useEffect, useState } from "react";

import AppleIcon from "@mui/icons-material/Apple";
import Button from "@component/Button/Button";
import { ErrorType } from "@component/PaymentDetails/PaymentDetails";
import { applePayLabels } from "vendor/rooamtab/utils/constants";
import trackGaEvent from "@utils/trackGaEvent";
import { usePlace } from "@context/PlaceContext";

interface ApplePayTokenProps {
  clientInstance: Client | undefined;
  onSuccess: (
    nonce: braintree.ApplePayPayload["nonce"],
    paymentType: braintree.ApplePayPayload["type"]
  ) => void;
  onError: (error: React.ReactNode) => void;
  setError: React.Dispatch<React.SetStateAction<ErrorType | undefined>>;
}

function ApplePayToken({
  clientInstance: client,
  onSuccess,
  onError,
  setError,
}: ApplePayTokenProps) {
  const [applePayInstance, setApplePayInstance] =
    useState<braintree.ApplePay>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { place } = usePlace();

  const setTokenizeErrorState = useCallback(
    (session?: ApplePaySession) => {
      setError("tokenizeError");
      setIsLoading(false);

      onError(
        <>
          <strong>Error:</strong> There was an error processing Apple Pay.
          Please try again or contact your server.
        </>
      );

      trackGaEvent("Error: ApplePay performCheckout", {
        description: `PlaceCode: ${place?.code}`,
      });

      session?.completePayment(ApplePaySession.STATUS_FAILURE);
    },
    [onError, place?.code, setError]
  );

  const createApplePay = useCallback(async () => {
    if (client) {
      await braintree.applePay
        .create({ client })
        .then((applePayInstance) => {
          setApplePayInstance(applePayInstance);
        })
        .catch((error) => {
          console.error(
            "ApplePay > Error Creating Braintree Payment instance",
            error
          );
          setTokenizeErrorState();
        });
    }
  }, [client, setTokenizeErrorState]);

  useEffect(() => {
    if (client) {
      createApplePay().catch((error) => console.error(error));
    }
  }, [client, createApplePay]);

  const braintreePaymentRequest = applePayInstance?.createPaymentRequest({
    total: {
      label: applePayLabels.paymentSheet.totalLabel,
      amount: "0.01",
    },
    requiredBillingContactFields: ["name", "postalAddress"],
  });

  const getApplePayToken = useCallback(() => {
    if (braintreePaymentRequest) {
      setError(undefined);
      setIsLoading(true);

      const session = new ApplePaySession(3, braintreePaymentRequest as any);

      session.oncancel = (event: ApplePayJS.Event): void => {
        event.cancelBubble = true;
        setIsLoading(false);

        trackGaEvent("ApplePay onCancel by user", {
          description: `PlaceCode: ${place?.code}`,
        });
      };

      session.onpaymentauthorized = (
        event: ApplePayJS.ApplePayPaymentAuthorizedEvent
      ) => {
        if (event.payment) {
          applePayInstance
            ?.tokenize({ token: event.payment.token })
            .then((payload) => {
              onSuccess(payload.nonce, payload.type);
            })
            .catch((error) => {
              setTokenizeErrorState();
              console.error("ApplePay > onpaymentauthorized >", error);

              trackGaEvent("Error: ApplePay tokenize", {
                description: `PlaceCode: ${place?.code}`,
              });
            });
        }
      };

      session.onvalidatemerchant = (
        event: ApplePayJS.ApplePayValidateMerchantEvent
      ) => {
        if (event.validationURL) {
          applePayInstance
            ?.performValidation({
              validationURL: event.validationURL,
              displayName: applePayLabels.validation.displayName,
            })
            .then((merchantSession) => {
              session.completeMerchantValidation(merchantSession);
            })
            .catch((error) => {
              setTokenizeErrorState();
              console.error("ApplePay > Error Validating Merchant", error);
              setIsLoading(false);

              trackGaEvent("Error: Validating ApplePay Merchant", {
                description: `PlaceCode: ${place?.code}`,
              });

              try {
                session.completePayment(ApplePaySession.STATUS_FAILURE);
              } catch (error) {
                console.error(
                  "ApplePay > Unable to set failure status for session",
                  session,
                  error
                );
                setTokenizeErrorState();
              }
              session.abort();
            });
        } else {
          console.error("ApplePay > Missing Merchant Validation URL", event);
          setIsLoading(false);
          setTokenizeErrorState();

          trackGaEvent("Error: Missing Apple Pay Merchant Validation URL", {
            description: `PlaceCode: ${place?.code}`,
          });
        }
      };

      session.begin();
    }

    trackGaEvent("Clicked ApplePay Button", {
      description: `PlaceCode: ${place?.code}`,
    });
  }, [
    applePayInstance,
    braintreePaymentRequest,
    onSuccess,
    place?.code,
    setError,
    setTokenizeErrorState,
  ]);

  return (
    <>
      <Button
        className="expressCheckoutBtn"
        node={
          <>
            Pay with <AppleIcon className="pay-icon" /> Pay
          </>
        }
        isLoading={isLoading}
        onClick={getApplePayToken}
        size="medium"
      />
    </>
  );
}

export default ApplePayToken;
