import { TextField } from "@mui/material";
import { colors } from "@theme/Themes";
import styled from "styled-components";

export const TextInput = styled(TextField)`
  &.overwrite {
    * {
      font-size: 1.5rem;
    }

    label {
      line-height: 1;
    }

    .MuiInputBase-formControl {
      &:hover {
        box-shadow: 0 0 0 3px rgb(55 112 219 / 7%);

        fieldset {
          border-color: ${colors.border};
        }
      }

      fieldset {
        border-width: 1px;
      }

      input {
        padding: 13.22px 11px 13.22px;
        line-height: 22px;

        &:hover + fieldset {
          border-color: ${colors.primary};
        }
      }
    }

    .MuiInputBase-adornedStart {
      padding-left: 0;
    }

    .MuiInputAdornment-positionStart {
      background-color: ${colors.background};
      border-right: 1px solid ${colors.border};
      display: flex;
      height: 55px;
      max-height: 100%;
      padding: 0 13px;

      p {
        color: ${colors.text};
        font-weight: 500;
        margin: 0;
      }
    }
  }
`;
