import StyledContainer from "@GlobalComponents/StyledContainer";
import styled from "styled-components";

export const Container = styled(StyledContainer)`
  padding: 16px;
  text-align: center;
`;

export const Header = styled(StyledContainer)`
  align-items: center;
  gap: 8px;
`;

export const Title = styled.h3`
  margin: 0;
`;

export const Message = styled.div`
  margin: 8px auto 32px;
`;

export const Buttons = styled(StyledContainer)`
  gap: 16px;
`;
