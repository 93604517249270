import StyledContainer from "@GlobalComponents/StyledContainer";
import { colors } from "@theme/Themes";
import styled from "styled-components";

export const FormRow = styled(StyledContainer)`
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 12px;

  small {
    display: flex;
    flex: 0 0 100%;
    width: 100%;
  }

  .MuiFormControl-root {
    flex: 1 1 50%;

    > div {
      border-radius: 0;
      border-color: transparent;
    }

    &:first-child {
      > div {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        border-right: 1px solid ${colors.border};
      }
    }

    &:last-child {
      > div {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        border-left: 1px solid ${colors.border};
      }
    }
  }
`;
