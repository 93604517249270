import LinearProgress from "@mui/material/LinearProgress";
import StyledContainer from "@GlobalComponents/StyledContainer";
import { colors } from "@theme/Themes";
import styled from "styled-components";

export const Container = styled(StyledContainer)`
  &.row-icon {
    align-items: center;
    justify-content: center;
    padding: 54px 0;

    h4 {
      margin: 10px 0 0;
    }
  }

  &.progress-bar {
    border: 1px solid ${colors.border};
    border-radius: 6px;
    gap: 14px;
    margin-bottom: 32px;
    padding: 22px;
  }

  &.progress-row {
    justify-content: space-between;
    gap: 8px;

    &:last-child {
      color: ${colors.textSecondary};
      font-weight: 500;

      strong {
        color: ${colors.text};
      }
    }
  }
`;

export interface CheckIconColorProps {
  outerBackgroundColor?: string;
  innerBackgroundColor?: string;
  fillColor?: string;
}

export const CheckIconContainer = styled(StyledContainer)<CheckIconColorProps>`
  align-items: center;
  background-color: ${(props) =>
    props.outerBackgroundColor ?? colors.successLight};
  border-radius: 50%;
  height: 100px;
  justify-content: center;
  position: relative;
  width: 100px;

  &:before {
    background-color: ${(props) =>
      props.innerBackgroundColor ?? colors.success};
    border-radius: 50%;
    content: "";
    height: 80px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80px;
    z-index: 0;
  }

  svg {
    fill: ${(props) => props.fillColor ?? colors.borderLight};
    height: 38px;
    position: relative;
    width: 38px;
    z-index: 1;
  }

  &.split {
    background-color: ${colors.border};

    &:before {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
`;

export const LinearProgressBar = styled(LinearProgress)`
  &.overwrite {
    height: 12px;
    border-radius: 6px;
    background-color: ${colors.border};

    .MuiLinearProgress-barColorPrimary {
      border-radius: 6px;
    }
  }
`;
