import * as Styled from "./TipInput.styles";

import React, { useEffect, useRef } from "react";

import { InputAdornment } from "@mui/material";

interface TipInputProps {
  defaultValue?: string;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onFocus?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  resetInput?: boolean;
}

function TipInput({
  defaultValue,
  onChange,
  resetInput,
}: TipInputProps): React.ReactElement {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (resetInput && inputRef.current?.value) {
      inputRef.current.value = "";
    }
  }, [resetInput]);

  // TODO: Should we use a lower value like 1000?
  const MaxTipValue = Number.MAX_VALUE;

  return (
    <Styled.Container direction="column">
      <Styled.Label>Or Custom Tip Amount</Styled.Label>
      <Styled.Input
        inputRef={inputRef}
        id="outlined-adornment-amount"
        className="overwrite"
        onChange={onChange}
        startAdornment={<InputAdornment position="start">$</InputAdornment>}
        placeholder={defaultValue ?? "Enter Custom Tip Amount"}
        type="number"
        inputProps={{ type: "number", min: 0, max: MaxTipValue }}
      />
    </Styled.Container>
  );
}

export default TipInput;
