import styled, { keyframes } from "styled-components";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const bounce = keyframes`
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-4px);
    }
    60% {
        transform: translateY(-2px);
    }
`;

export const BouncingWrapper = styled.div`
  animation: ${bounce} 3s infinite;
`;

function BouncingChevronDown() {
  return (
    <BouncingWrapper>
      <ExpandMoreIcon fontSize="large" />
    </BouncingWrapper>
  );
}

export default BouncingChevronDown;
