import apiClient from "./api/apiClient";
import { environment } from "utils/constants";

interface Result {
  clientToken: string;
  type: string;
}

export async function getGuestToken(): Promise<Result> {
  try {
    return await apiClient.get({ path: clientTokenEndpoint });
  } catch (error) {
    throw new Error("Error getting Braintree Guest Token");
  }
}

export async function getResyToken(
  resyAuthToken: string | undefined
): Promise<Result> {
  const queryHeaders = resyAuthToken ? { resyAuthToken } : undefined;

  try {
    return await apiClient.get({
      path: clientTokenEndpoint,
      headers: queryHeaders,
    });
  } catch (error) {
    throw new Error("Error getting Braintree Resy Token");
  }
}

export const clientTokenEndpoint = `${environment.baseUrl}/braintree/clientToken`;
