import StyledContainer from "@GlobalComponents/StyledContainer";
import styled from "styled-components";

export const Container = styled(StyledContainer)`
  margin-bottom: 32px;

  button {
    div {
      align-items: center;
      justify-content: center;
      display: flex;
      gap: 8px;
      width: 100%;

      .MuiSvgIcon-root {
        height: 20px;
        width: 20px;
      }
    }
  }
`;

export const Title = styled.h1`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 6rem;
  line-height: 4.5rem;
  justify-content: center;

  small {
    font-size: 1.6rem;
    font-weight: 500;
  }
`;
