import { LogoContainer } from "./VendorLogo.styles";
import { ReactComponent as RooamLogo } from "@vendor/assets/logo.svg";

const VendorLogo = (): React.ReactElement => {
  return (
    <LogoContainer>
      <RooamLogo title="vendor-logo" />
    </LogoContainer>
  );
};

export default VendorLogo;
