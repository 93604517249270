import { TabNumber } from "@component/EmptyTab/EmptyTab.styles";
import { addBreadcrumb } from "@sentry/react";
import { environment } from "./constants";

interface CloseTabProps {
  placeCode: string;
  tip: string;
  tabNumber?: string;
}

async function closeTab({ placeCode, tip }: CloseTabProps) {
  const xRooamSession = localStorage.getItem("x-rooam-session");

  if (!xRooamSession) {
    throw new Error("Session token not found");
  }

  const headers = new Headers();
  headers.append("content-type", "application/json");
  environment.originUrl && headers.append("origin", environment.originUrl);
  environment.refererUrl && headers.append("referer", environment.refererUrl);
  xRooamSession && headers.append("x-rooam-session", xRooamSession);

  const body = JSON.stringify({
    placeCode,
    tipAmount: tip,
  });

  try {
    addBreadcrumb({
      level: "info",
      category: "user_action",
      message: `Close Tab ${TabNumber} at Place ${placeCode}`,
    });

    const response = await fetch(`${environment.baseUrl}/close`, {
      method: "POST",
      headers,
      body,
    });

    if (!response.ok) {
      throw new Error(`Request failed with status ${response.status}`);
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
  }
}

export default closeTab;
