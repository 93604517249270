import * as Styled from "./IdentityVerification.styles";

import Button from "@component/Button/Button";
import IdentityVerificationImg from "@vendor/assets/images/identity-verification.png";
import { useCallback } from "react";

function IdentityVerification() {
  const handleIdentityVerification = useCallback(() => {
    console.log("handleIdentityVerification");
  }, []);

  const handleLogOut = useCallback(() => {
    console.log("handleLogOut");
  }, []);

  return (
    <Styled.Container direction="column">
      <h3>Identity Verification Required</h3>
      <Styled.VerificationImage
        src={IdentityVerificationImg}
        alt="Verify your identity"
        width="100%"
      />
      <Styled.Paragraph>
        Before opening a Tab at this place, we require to verify your identity.
      </Styled.Paragraph>
      <Styled.SmallText>
        This verification process is provided and operated through a secure
        identity verification provider.
      </Styled.SmallText>

      <Styled.ButtonContainer direction="column">
        <Button text="Start" onClick={handleIdentityVerification} />
        <Button text="Log Out" onClick={handleLogOut} variant="outlined" />
      </Styled.ButtonContainer>
    </Styled.Container>
  );
}

export default IdentityVerification;
