import { environment } from "./constants";
import getNextScreen from "./getNextScreen";
import { setUser } from "@sentry/react";

interface VerifyCodeAndLoginProps {
  number: string;
  code: string;
  placeCode: string;
}

async function verifyCodeAndLogin({
  number,
  code,
  placeCode,
}: VerifyCodeAndLoginProps) {
  const url = `${environment.baseUrl}/login`;
  const xRooamSession = localStorage.getItem("x-rooam-session");

  if (!xRooamSession) {
    throw new Error("Session token not found");
  }

  const headers = new Headers();
  headers.append("content-type", "application/json");
  environment.originUrl && headers.append("origin", environment.originUrl);
  environment.refererUrl && headers.append("referer", environment.refererUrl);
  headers.append("x-rooam-session", xRooamSession);

  try {
    const response = await fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify({
        number,
        code,
        placeCode,
      }),
    });

    if (!response.ok) {
      throw new Error(`Request failed with status ${response.status}`);
    }

    const newSessionToken = response.headers.get("x-rooam-session");

    // Validate if sessionToken is not null before returning
    if (newSessionToken === null) {
      throw new Error("Failed to get new session token");
    }

    localStorage.setItem("x-rooam-session", newSessionToken);

    setUser({
      username: `${placeCode}_${number}`,
    });

    return await getNextScreen();
  } catch (error) {
    console.error(`Failed to validate PIN Code: ${error}`);
  }
}

export default verifyCodeAndLogin;
