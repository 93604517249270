import StyledContainer from "@GlobalComponents/StyledContainer";
import styled from "styled-components";

export const Form = styled.form`
  padding-top: 32px;
`;

export const Container = styled(StyledContainer)`
  gap: 8px;

  &.single-column {
    > div {
      max-width: calc(50% - 4px);
    }
  }

  button {
    margin-top: 18px;
  }
`;

export const Row = styled(StyledContainer)`
  align-items: flex-start;
  gap: 16px;
`;

export const EmptyColumn = styled.div`
  display: flex;
  flex: 50% 1 0;
`;
