import * as Styled from "./VendorFooter.styles";

import { ReactComponent as AppleStoreImg } from "assets/app-store.svg";
import { ReactComponent as PlayStoreImg } from "assets/playstore-btn.svg";
import { useLocation } from "react-router-dom";

function VendorButtons() {
  const location = useLocation();
  const { pathname } = location;

  if (!pathname.includes("/phone") && !pathname.includes("/complete")) {
    return <></>;
  }

  return (
    <Styled.AppButtonsContainer direction="column">
      <strong>Download the Rooam App For More Features</strong>
      <Styled.AppButtons>
        <a
          href="https://apps.apple.com/us/app/rooam-pay-from-your-phone/id1141914731?itsct=apps_box_badge&amp;itscg=30200"
          target="_blank"
          rel="noreferrer"
          title="Download Rooam Pay for iPhone"
        >
          <AppleStoreImg title="appleStore-img" />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=co.rooam.rooamapp&utm_source=web_tab&utm_campaign=install_promote&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          target="_blank"
          rel="noreferrer"
          title="Download Rooam Pay for Android"
        >
          <PlayStoreImg title="playStore-img" />
        </a>
      </Styled.AppButtons>
    </Styled.AppButtonsContainer>
  );
}

export default VendorButtons;
