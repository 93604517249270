import * as Styled from "./TabPaymentMethod.styles";

import { Typography } from "@mui/material";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useTab } from "@context/TabContext";

function TabPaymentMethod() {
  const { feed, placeCode } = useTab();

  const navigate = useNavigate();

  const handleChangePaymentMethod = useCallback(() => {
    navigate(`/venue/${placeCode}/addpayment`);
  }, [navigate, placeCode]);

  return (
    <Styled.MainContainer direction="column">
      <Typography id="payment-method" className="tips-title" variant="h2">
        <a href="#payment-method">Your Payment Method</a>
      </Typography>
      <Styled.Container>
        <img
          width={28}
          src={feed?.paymentMethod.image}
          alt="Selected Payment Method icon"
        />
        <span>{feed?.paymentMethod?.label}</span>
        <Styled.ChangeButton
          className="overwrite"
          text="Change"
          variant="outlined"
          size="small"
          onClick={handleChangePaymentMethod}
        />
      </Styled.Container>
    </Styled.MainContainer>
  );
}

export default TabPaymentMethod;
