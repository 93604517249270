import * as Styled from "./TabSummary.styles";

import { ReactNode, useCallback, useState } from "react";
import { SummaryRow, SummaryTitleValue } from "@component/Summary/Summary";

import Button from "@component/Button/Button";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import OrderList from "@component/Order/OrderList";
import { ReceiptData } from "@utils/getTabReceipt";

interface TabSummaryProps {
  subtotal: string;
  tax: string;
  fees: string;
  tip: string;
  total: string;
  items?: ReceiptData["items"];
  discount?: string;
  collapsible: boolean;
}

function TabSummary({
  subtotal,
  tax,
  fees,
  tip,
  total,
  items,
  discount,
  collapsible,
}: TabSummaryProps) {
  const [isFeesExpanded, setIsFeesExpanded] = useState<boolean>(false);

  const totalTaxesFees = (
    parseFloat(tax ?? "") + parseFloat(fees ?? "")
  ).toFixed(2);

  const handleFeesCollapse = useCallback(
    (event: React.SyntheticEvent<Element, Event>) => {
      event.preventDefault();

      setIsFeesExpanded(!isFeesExpanded);
    },
    [isFeesExpanded]
  );

  return (
    <SummaryContainer total={total} collapsible={collapsible} items={items}>
      <Styled.Container direction="column">
        {discount && (
          <Styled.Row className="discount">
            <span>Discount: </span>
            <span>${discount}</span>
          </Styled.Row>
        )}
        <Styled.Row>
          <span>Subtotal: </span>
          <span>${subtotal}</span>
        </Styled.Row>
        <Styled.Row>
          <Styled.AccordionContainer
            className="inner-accordion"
            expanded={isFeesExpanded}
            onChange={handleFeesCollapse}
            disabled={!fees}
            square={true}
            id="taxes-fees-accordion"
          >
            <Styled.Title className="overwrite">
              <SummaryTitleValue
                title={
                  <>
                    {fees ? "Taxes & Fees:" : "Tax:"}
                    {!!fees && <ExpandCircleDownIcon />}
                  </>
                }
                value={`$${totalTaxesFees.toString()}`}
                titleColumnId={"summary-taxes-and-fees-amount"}
              />
            </Styled.Title>
            <Styled.AccordionDetails>
              {!!tax && (
                <SummaryRow
                  title={"Tax:"}
                  value={`$${tax}`}
                  valueColumnId={"summary-taxes-amount"}
                />
              )}
              {!!fees && (
                <>
                  <SummaryRow
                    title={"Fees:"}
                    value={`$${fees}`}
                    valueColumnId={"summary-fees-amount"}
                  />
                  <Styled.Row>
                    <p>
                      This fee helps cover the convenience costs of mobile
                      payments, additional security to prevent fraud and
                      maintain a high EMV level to stay PCI compliant.
                    </p>
                  </Styled.Row>
                </>
              )}
            </Styled.AccordionDetails>
          </Styled.AccordionContainer>
        </Styled.Row>
        <Styled.Row>
          <span>Tip: </span>
          <span>${tip}</span>
        </Styled.Row>
        {!collapsible && (
          <Styled.Row className="tab-total">
            <span>Total: </span>
            <span>${total}</span>
          </Styled.Row>
        )}
      </Styled.Container>
    </SummaryContainer>
  );
}

interface SummaryContainerProps {
  total?: string;
  children: ReactNode;
  collapsible?: boolean;
  items?: ReceiptData["items"];
}

const SummaryContainer = ({
  total,
  children,
  collapsible,
  items,
}: SummaryContainerProps): React.ReactElement => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [showItems, setShowItems] = useState<boolean>(false);

  const handleCollapse = useCallback(
    (event: React.SyntheticEvent<Element, Event>) => {
      event.preventDefault();
      setIsExpanded(!isExpanded);
    },
    [isExpanded]
  );

  const handleShowItems = useCallback(() => {
    setShowItems(!showItems);
  }, [showItems]);

  if (collapsible) {
    return (
      <Styled.AccordionContainer
        className={`overwrite ${collapsible && "collapsible"}`}
        id="summary-accordion"
        expanded={isExpanded}
        onChange={handleCollapse}
        square={true}
      >
        <Styled.Title
          className="overwrite"
          collapsible={collapsible ? "true" : "false"}
        >
          <div>
            <span>
              Tab Details <ExpandCircleDownIcon />
            </span>
            <span>{total}</span>
          </div>
        </Styled.Title>

        {items && (
          <Button
            className="view-items-btn"
            text="View items"
            variant="outlined"
            size="small"
            onClick={handleShowItems}
          />
        )}

        {items && showItems && (
          <Styled.ItemListContainer direction="column">
            <OrderList items={items} />
          </Styled.ItemListContainer>
        )}

        <Styled.AccordionDetails className="overwrite collapsible-details">
          {children}
        </Styled.AccordionDetails>
      </Styled.AccordionContainer>
    );
  }

  return <Styled.RowContainer id="summary">{children}</Styled.RowContainer>;
};

export default TabSummary;
