import * as Styled from "./VendorTopBar.styles";

import React, { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { ArrowBack } from "@mui/icons-material";
import Button from "@component/Button/Button";
import LocationImgPlaceholder from "@vendor/assets/images/location-image.jpg";
import { usePlace } from "@context/PlaceContext";

const VendorTopBar = () => {
  const { place } = usePlace();

  const { pathname } = useLocation();

  const navigate = useNavigate();

  const handleGoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const fullHeroSize = !!pathname.includes("/phone");

  return (
    <>
      <Styled.Wrapper direction="column">
        <Styled.LocationHero fullHeroSize={fullHeroSize}>
          <img src={place?.image ?? LocationImgPlaceholder} alt="Location" />
          {!fullHeroSize && (
            <>
              <Button
                className="back-btn"
                icon={<ArrowBack fontSize="large" color="inherit" />}
                onClick={handleGoBack}
                size="small"
                variant="text"
              />
              <h1>{place?.name}</h1>
            </>
          )}
        </Styled.LocationHero>
        {fullHeroSize && (
          <Styled.LocationInfo direction="column">
            <Styled.LocationProfileImg>
              {place?.logo && (
                <img src={place.logo} alt={`${place.name} logo`} />
              )}
            </Styled.LocationProfileImg>

            {place?.name && (
              <Styled.LocationName>
                <h1>{place?.name}</h1>
                <span>{place.address}</span>
              </Styled.LocationName>
            )}
          </Styled.LocationInfo>
        )}
      </Styled.Wrapper>
    </>
  );
};

export default VendorTopBar;
