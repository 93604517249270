import * as Styled from "./PhoneAdBanner.styles";

interface PhoneBannerProps {
  type: string;
  imageUrl: string;
  text: string;
  theme: {
    backgroundColor: string;
    borderColor: string;
  };
  campaignId: string;
}

/* Placeholder data */
const data: PhoneBannerProps = {
  type: "BANNER_SMALL",
  imageUrl:
    "https://rooam-web-tab.netlify.app/static/liquid-death-defccf05a03fad58ea65cffd3c796f2e.png",
  text: "These infinitely recyclable cans of pure mountain water won't just refresh your body, they will murder your thirst.",
  theme: {
    backgroundColor: "#393939",
    borderColor: "#a0a0a0",
  },
  campaignId: "624f1030ba9da37ad6ec71d0",
};

function PhoneAdBanner() {
  return (
    <Styled.Container colorTheme={data.theme}>
      <Styled.BannerImage src={data.imageUrl} alt="Location Ad banner" />
      <span>{data.text}</span>
    </Styled.Container>
  );
}

export default PhoneAdBanner;
