import * as Styled from "./TabActionSheet.styles";

import { BottomSheet } from "react-spring-bottom-sheet";
import BouncingChevronDown from "@component/Icons/BouncingChevronDown";
import Button from "@component/Button/Button";
import { colors } from "@theme/Themes";

interface TabActionSheetProps {
  isActionSheetOpen: boolean;
  onActionSheetOpen: () => void;
  onPerformAction: () => void;
  isActionLoading: boolean;
  title?: string;
  message?: string;
  actionText: string;
}

function TabActionSheet({
  isActionSheetOpen,
  onActionSheetOpen,
  onPerformAction,
  isActionLoading,
  title,
  message,
  actionText,
}: TabActionSheetProps) {
  return (
    <BottomSheet
      id="bottom-sheet"
      open={isActionSheetOpen}
      onDismiss={onActionSheetOpen}
      style={{
        backgroundColor: `${colors.lighterBackground}`,
      }}
    >
      <Styled.Container direction="column">
        {title && (
          <Styled.Header direction="column">
            <Styled.Title>{title}</Styled.Title>
            <BouncingChevronDown />
          </Styled.Header>
        )}
        {message && <Styled.Message>{message}</Styled.Message>}
        <Styled.Buttons>
          <Button
            disabled={isActionLoading}
            text="Cancel"
            variant="outlined"
            onClick={onActionSheetOpen}
          />
          <Button
            isLoading={isActionLoading}
            text={actionText}
            onClick={onPerformAction}
          />
        </Styled.Buttons>
      </Styled.Container>
    </BottomSheet>
  );
}

export default TabActionSheet;
