import apiClient from "./api/apiClient";

async function getPaymentMethods(
  resyAuthToken: string | undefined
): Promise<any> {
  const queryHeaders = resyAuthToken ? { resyAuthToken } : undefined;

  try {
    return  await apiClient.get({ path: "/resy/paymentMethods", headers: queryHeaders });
  } catch (error) {
    console.error("Error getting user's payment methods", error);
  }
}

export default getPaymentMethods;
