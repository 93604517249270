import * as Styled from "./WebTabHostedFields.styles";

import BraintreeField from "@component/BraintreeField/BraintreeField";
import Button from "@component/Button/Button";
import { ErrorType } from "@component/PaymentDetails/PaymentDetails";

interface WebTabHostedFieldsProps {
  error: ErrorType | undefined;
  isLoading: boolean;
  onConnect: () => void;
}

function WebTabHostedFields({
  error,
  isLoading,
  onConnect,
}: WebTabHostedFieldsProps) {
  return (
    <>
      <Styled.FormRow>
        <BraintreeField
          error={error?.includes("cardholderName") ?? false}
          errorText="Enter a valid name."
          id="cardholder-name"
          label="Cardholder Name"
        />
      </Styled.FormRow>
      <Styled.FormRow>
        <BraintreeField
          error={error?.includes("number") ?? false}
          errorText="Enter a valid card number."
          id="cc-number"
          label="Card Number"
        />
      </Styled.FormRow>
      <Styled.FormRow>
        <BraintreeField
          error={error?.includes("expirationDate") ?? false}
          errorText="Enter a valid expiry date."
          id="cc-expiration"
          label="Expires"
        />
        <BraintreeField
          error={error?.includes("cvv") ?? false}
          errorText="Enter a valid CVV."
          id="cc-cvv"
          label="CVV"
        />
      </Styled.FormRow>
      <Styled.FormRow>
        <BraintreeField
          error={error?.includes("postalCode") ?? false}
          errorText="Enter a valid Zip Code."
          id="cc-zip"
          label="Zip Code"
        />
      </Styled.FormRow>
      <Button
        color="secondary"
        isLoading={isLoading}
        text="Connect"
        onClick={onConnect}
      />
    </>
  );
}

export default WebTabHostedFields;
