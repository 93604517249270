import {
  Details,
  AccordionContainer as SummaryAccordionContainer,
  RowContainer as SummaryRowContainer,
  Title as SummaryTitle,
} from "@component/Summary/Summary.styles";

import StyledContainer from "@GlobalComponents/StyledContainer";
import { colors } from "@theme/Themes";
import styled from "styled-components";

export const Container = styled(StyledContainer)`
  gap: 3px;

  .MuiAccordionSummary-content {
    > * {
      color: ${colors.text} !important;
    }
  }

  .MuiAccordionDetails-root {
    > div > div {
      color: ${colors.text} !important;
    }
  }
`;

export const RowContainer = styled(SummaryRowContainer)``;

export const Row = styled(StyledContainer)`
  color: ${colors.text};
  font-weight: 400;
  font-size: 1.4rem;

  &.tab-total {
    background-color: ${colors.paper};
    font-size: 1.6rem;
    font-weight: 800;
    margin-top: 8px;
    padding: 8px 0;
    position: relative;
    z-index: 1;

    &:before {
      background-color: ${colors.paper};
      border-bottom: 1px solid ${colors.border};
      border-top: 1px solid ${colors.border};
      content: " ";
      height: 100%;
      left: -50%;
      position: absolute;
      top: 0;
      width: 200%;
      z-index: -1;
    }
  }

  &.discount {
    color: ${colors.success};
  }

  span {
    &:last-child {
      margin-left: auto;
    }
  }
`;

export const AccordionContainer = styled(SummaryAccordionContainer)`
  .view-items-btn {
    margin: 16px auto 0;
  }
`;

export const AccordionDetails = styled(Details)`
  &.MuiAccordionDetails-root {
    > div {
      &:first-child {
        border-top: ${colors.border} solid 1px;
      }
      &:last-child {
        border-bottom: ${colors.border} solid 1px;
      }

      p {
        margin-top: 0;
        font-size: 1.2rem;
      }
    }
  }

  &.collapsible-details {
    > div {
      &:first-child {
        border-top: none;
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
`;

export const Title = styled(SummaryTitle)``;

export const ItemListContainer = styled(StyledContainer)`
  border-bottom: 1px solid ${colors.border};
  margin-top: 16px;
  padding: 0 8px 16px;
  width: 100%;

  .MuiAvatar-circular {
    height: 34px !important;
    width: 34px !important;

    .MuiAvatar-root {
      font-size: 1.4rem;
      height: 24px !important;
      width: 24px !important;
    }
  }

  .MuiPaper-root {
    padding: 6px 8px;

    > div {
      span {
        &:last-child {
          display: none;
        }
      }
    }
  }
`;
