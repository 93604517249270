import StyledContainer from "@GlobalComponents/StyledContainer";
import { colors } from "@theme/Themes";
import styled from "styled-components";

export const Container = styled(StyledContainer)`
  align-items: center;
  margin: auto auto;
  max-width: 280px;
  text-align: center;
  padding-top: 32px;

  p {
    margin: 0;
  }
`;

export interface CheckIconColorProps {
  outerBackgroundColor?: string;
  innerBackgroundColor?: string;
  size: number;
}

export const CheckIconContainer = styled(StyledContainer)<CheckIconColorProps>`
  align-items: center;
  background-color: ${(props) =>
    props.outerBackgroundColor ?? colors.successLight};
  border-radius: 50%;
  height: ${({ size }) => `${size}px`};
  justify-content: center;
  position: relative;
  width: ${({ size }) => `${size}px`};

  &:before {
    background-color: ${(props) =>
      props.innerBackgroundColor ?? colors.success};
    border-radius: 50%;
    content: "";
    height: ${({ size }) => `calc(${size}px - 25%)`};
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: ${({ size }) => `calc(${size}px - 25%)`};
    z-index: 0;
  }

  svg {
    fill: #ffffff;
    height: ${({ size }) => `calc(${size}px - 50%)`};
    position: relative;
    width: ${({ size }) => `calc(${size}px - 50%)`};
    z-index: 1;
  }
`;

export const Title = styled.h2`
  color: ${colors.success};
  margin-bottom: 0;
`;

export const TabNumber = styled.span`
  font-size: 6rem;
  font-weight: 700;
`;
