import getTabOverdue, { TabOverdueData } from "./getTabOverdue";

import { TabItemData } from "./getTabFeed/getTabFeed";
import { environment } from "./constants";

export interface ReceiptData {
  receipt: {
    total: string;
    subTotal: string;
    fee: string;
    tip: string;
    tax: string;
    ticketNumber: string;
    serviceCharges: string;
    partialPayments: string[];
    discounts: string[];
    totalWithCredit: string;
    creditApplied: string;
  };
  place: {
    name: string;
  };
  items: TabItemData[];
}

export interface TabReceiptResponse {
  data: ReceiptData | undefined;
  overdue: TabOverdueData | undefined;
}

async function getTabReceipt(): Promise<TabReceiptResponse | undefined> {
  const xRooamSession = localStorage.getItem("x-rooam-session");

  const headers = new Headers();
  headers.append("content-type", "application/json");
  environment.originUrl && headers.append("origin", environment.originUrl);
  xRooamSession && headers.append("X-Rooam-Session-Static", xRooamSession);

  try {
    const response = await fetch(`${environment.baseUrl}/receipt`, {
      method: "GET",
      headers,
    });

    if (!response.ok) {
      const overdue = await getTabOverdue();

      if (overdue?.hasOverduePayment) {
        return {
          data: undefined,
          overdue,
        };
      }

      throw new Error(`Request failed with status ${response.status}`);
    }

    const data = await response.json();

    return {
      data,
      overdue: undefined,
    };
  } catch (error) {
    console.error(error);
  }
}

export default getTabReceipt;
