import * as Styled from "./PaytronixRewards.styles";

import { getItem, setItem } from "@hooks/useCookies";
import { isFalsy, isTruthy } from "@utils/truthy";
import { useCallback, useEffect, useRef, useState } from "react";

import AmountInput from "components/Inputs/AmountInput/AmountInput";
import Button from "@component/Button/Button";
import FullScreenLoader from "@component/Loading/FullScreenLoader";
import StyledSwitch from "@GlobalComponents/StyledSwitch";
import { Timeout } from "react-number-format/types/types";
import VendorRewardsIcon from "@vendor/components/VendorLogo/VendorRewardsIcon";
import { applyPaytronixRewardPoints } from "@utils/getCheck/paytronix/applyPaytronixRewardPoints";
import { colors } from "@theme/Themes";
import paytronixUtils from "@utils/getCheck/paytronix/paytronixUtils";
import { queryKeys } from "@utils/constants";
import { toDollars } from "utils/utilities";
import trackGaEvent from "@utils/trackGaEvent";
import { useCheck } from "context/CheckContext";
import { usePlace } from "@context/PlaceContext";
import { useQueryClient } from "react-query";

interface AmexRewardsProps {
  rewardDollarsInCents: number;
}

function PaytronixRewards({ rewardDollarsInCents }: AmexRewardsProps) {
  const [selected, setSelected] = useState<boolean>(false);
  const [pointsAppliedInDollars, setPointsAppliedInDollars] =
    useState<number>();
  const [isExceeded, setIsExceeded] = useState<boolean>(false);
  const [isZeroValue, setIsZeroValue] = useState<boolean>(false);

  const [isRedeemingRewardDollars, setIsRedeemingRewardDollars] =
    useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const [
    totalAmountInCentsBeforePointsApplied,
    setTotalAmountInCentsBeforePointsApplied,
  ] = useState<number | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const applyRewardDollarsTimerRef = useRef<Timeout | undefined>(undefined);

  const { splitCheck, check, selectedTip } = useCheck();
  const { place } = usePlace();

  const queryClient = useQueryClient();

  const checkFees = check?.totals?.feesInCents ?? 0;
  const dueAmountInCents = check?.totals?.dueInCents ?? 0;

  const selectedTipInCents = (selectedTip?.amount ?? 0) * 100;

  const checkTotalInCents = (
    dueAmountInCents +
    checkFees +
    selectedTipInCents
  ).round();

  const splitCheckTotalInCents = splitCheck?.amount
    ? (splitCheck?.amount + checkFees + selectedTipInCents).round()
    : undefined;

  const maxPointsInDollars = rewardDollarsInCents / 100;

  const storeAppliedRewardDollarInCents = useCallback(
    (dollarsInCents: number) => {
      setItem(`paytronix_applied_${check?.id}`, dollarsInCents, 1);

      setPointsAppliedInDollars(dollarsInCents * 100);
      setIsDisabled(true);
      setSelected(true);
    },
    [check?.id]
  );

  useEffect(() => {
    const storedAppliedRewardInCents = +(
      getItem(`paytronix_applied_${check?.id}`) ?? "0"
    );
    if (storedAppliedRewardInCents) {
      setPointsAppliedInDollars(storedAppliedRewardInCents / 100);
      setIsDisabled(true);
      setSelected(true);
    }
    const paytronixAppliedDiscount = check?.paytronix?.appliedDiscount;

    if (isTruthy(paytronixAppliedDiscount) && paytronixAppliedDiscount > 0) {
      storeAppliedRewardDollarInCents(paytronixAppliedDiscount * 100);
    }
  }, [
    check?.id,
    setPointsAppliedInDollars,
    check?.paytronix,
    storeAppliedRewardDollarInCents,
  ]);

  useEffect(() => {
    const currentTotalAmountInCents = check?.totals?.totalInCents;
    const previousTotalAmountInCents = totalAmountInCentsBeforePointsApplied;

    if (
      isRedeemingRewardDollars &&
      ((isTruthy(currentTotalAmountInCents) &&
        previousTotalAmountInCents &&
        previousTotalAmountInCents > currentTotalAmountInCents) ??
        isTruthy(check?.paytronix?.appliedDiscount))
    ) {
      setIsRedeemingRewardDollars(false);
    }

    // Clear the interval when the component unmounts
    return () => clearTimeout(applyRewardDollarsTimerRef.current);
  }, [
    check?.paytronix?.appliedDiscount,
    check?.totals.subTotalInCents,
    check?.totals?.totalInCents,
    isRedeemingRewardDollars,
    totalAmountInCentsBeforePointsApplied,
  ]);

  useEffect(() => {
    if (isExceeded) {
      setErrorMessage(
        "Cannot apply an amount greater than ticket total or available points."
      );
    } else if (isZeroValue) {
      setErrorMessage(
        "Can not apply selected amount. Please choose a higher value."
      );
    } else {
      setErrorMessage(undefined);
    }
  }, [isExceeded, isZeroValue]);

  const handleSwitch = useCallback(() => {
    setSelected(!selected);
  }, [selected]);

  const handleRewardPoints = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      let amount = parseFloat(event.currentTarget.value);
      amount = isNaN(amount) ? 0 : amount;
      const total = (splitCheckTotalInCents ?? checkTotalInCents).toDollars();

      const exceeded = amount > maxPointsInDollars || amount > total;
      setIsExceeded(exceeded);
      setPointsAppliedInDollars(exceeded ? 0 : amount);
      setIsZeroValue((exceeded ? 0 : amount) <= 0);
    },
    [splitCheckTotalInCents, checkTotalInCents, maxPointsInDollars]
  );

  const handleApplyRewardPoints = useCallback(() => {
    setIsRedeemingRewardDollars(true);
    setTotalAmountInCentsBeforePointsApplied(check?.totals.totalInCents);

    setErrorMessage(undefined);

    applyRewardDollarsTimerRef.current = setTimeout(() => {
      queryClient
        .invalidateQueries({ queryKey: [queryKeys.checkFeed] })
        .catch((error) => console.error("Invalidating query failed", error));

      setErrorMessage("Failure applying Rewards Dollars. Please try again.");
      setIsRedeemingRewardDollars(false);
    }, 50 * 1000);

    trackGaEvent("Clicked Apply Paytronix Reward Points Button", {
      description: `PlaceCode: ${place?.code} | CheckNumber: ${check?.number}`,
    });

    if (isFalsy(check)) {
      console.warn("Check data not available");
      return;
    }

    const paytronixMemberId =
      paytronixUtils.getData(check.id)?.paytronix.memberId ?? "";

    const rewardDollarsInCents = (pointsAppliedInDollars ?? 0) * 100;

    console.log(
      `Trying to Apply Paytronix Reward Points: ${rewardDollarsInCents}`
    );

    applyPaytronixRewardPoints({
      placeCode: check.placeCode,
      ticketId: check.id,
      memberId: paytronixMemberId,
      rewardDollarsInCentsToUse: rewardDollarsInCents,
    })
      .then((_) => {
        console.log(
          `Paytronix Reward Points Applied: ${pointsAppliedInDollars}`
        );

        queryClient
          .invalidateQueries({ queryKey: [queryKeys.checkFeed] })
          .catch((error) => console.error("Invalidating query failed", error));

        storeAppliedRewardDollarInCents(rewardDollarsInCents);
      })
      .catch((error) => {
        console.error("Error Applying Paytronix Reward Points", error);
        setErrorMessage("Failure applying Rewards Dollars. Please try again.");
        setIsRedeemingRewardDollars(false);
      });
  }, [
    check,
    place?.code,
    pointsAppliedInDollars,
    queryClient,
    storeAppliedRewardDollarInCents,
  ]);

  return (
    <>
      {isRedeemingRewardDollars && (
        <FullScreenLoader
          backgroundColor={colors.background + "E6"}
          title={"Applying Rewards Dollars"}
        />
      )}
      <Styled.PaytronixRewards direction="column">
        Would you like to Redeem Rewards?
        <Styled.Row>
          <Styled.Column direction="column" role="note">
            <VendorRewardsIcon />
          </Styled.Column>
          <Styled.Column
            className="rewards-info"
            direction="column"
            role="note"
          >
            <span>Rewards Dollars</span>
            <span>${toDollars(rewardDollarsInCents)} available</span>
          </Styled.Column>
          <Styled.Column className="center-items" direction="column">
            <StyledSwitch
              disabled={isDisabled}
              id="switch-pay-points"
              onClick={handleSwitch}
            />
          </Styled.Column>
        </Styled.Row>
        {selected && (
          <>
            <Styled.Row className="reward-points-row">
              <Styled.Column>
                <AmountInput
                  disabled={isDisabled}
                  id="reward-points-usd"
                  defaultValue={pointsAppliedInDollars?.toString()}
                  onChange={handleRewardPoints}
                />
              </Styled.Column>
              <Styled.Column>
                <Button
                  disabled={isDisabled || isExceeded || isZeroValue}
                  isLoading={isRedeemingRewardDollars}
                  text={isDisabled ? "Applied" : "Apply"}
                  variant="outlined"
                  onClick={handleApplyRewardPoints}
                />
              </Styled.Column>
            </Styled.Row>
            <Styled.Row className="terms" direction="column">
              {errorMessage && <div className="exceeded">{errorMessage}</div>}
              <div>
                Enter the amount of Rewards Dollars you would like to redeem.
                Redemption cannot exceed the subtotal amount. Your selection is
                final.
              </div>
            </Styled.Row>
          </>
        )}
      </Styled.PaytronixRewards>
    </>
  );
}

export default PaytronixRewards;
