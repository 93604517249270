import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    margin: 0 0 18px;
  }
`;

export const InnerContainer = styled(Container)`
  gap: 5px;

  button {
    margin-top: 5px;
  }
`;
