import StyledContainer from "@GlobalComponents/StyledContainer";
import { colors } from "@theme/Themes";
import styled from "styled-components";

export const Container = styled(StyledContainer)`
  text-align: center;
`;

export const VerificationImage = styled.img`
  border-radius: 6px;
`;

export const Paragraph = styled.p`
  margin: 32px auto;
  max-width: 410px;
  text-align: center;
`;

export const SmallText = styled.small`
  color: ${colors.textSecondary};
  margin-bottom: 32px;
`;

export const ButtonContainer = styled(StyledContainer)`
  gap: 12px;
`;
