import * as Styled from "./EmptyTab.styles";

import { useCallback, useState } from "react";

import Button from "@component/Button/Button";
import CheckIcon from "@mui/icons-material/Check";
import TabActionSheet from "@component/TabActionSheet/TabActionSheet";
import { useNavigate } from "react-router-dom";
import { useTab } from "@context/TabContext";

interface EmptyTabProps {
  placeCode?: string;
  tabNumber?: string;
}

function EmptyTab({ tabNumber, placeCode }: EmptyTabProps) {
  const [isActionSheetOpen, setIsActionSheetOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { closeOpenTab } = useTab();

  const navigate = useNavigate();

  const handleActionSheetIsOpen = useCallback(() => {
    setIsActionSheetOpen(!isActionSheetOpen);
  }, [isActionSheetOpen]);

  const handleCloseEmptyTab = useCallback(async () => {
    setIsLoading(true);

    const closeAndReturnNextScreen = await closeOpenTab({ tip: "20" });

    handleActionSheetIsOpen();

    if (closeAndReturnNextScreen) {
      navigate(`/venue/${placeCode}/voided`, {
        replace: true,
      });
    }

    setIsLoading(false);
  }, [closeOpenTab, handleActionSheetIsOpen, navigate, placeCode]);

  return (
    <>
      <Styled.Container direction="column">
        <Styled.CheckIconContainer size={50}>
          <CheckIcon />
        </Styled.CheckIconContainer>
        <Styled.Title>Your Tab is Open!</Styled.Title>
        <p>
          Show the tab number below and order with your bartender or server:
        </p>
        <Styled.TabNumber>{tabNumber}</Styled.TabNumber>
      </Styled.Container>
      <Button
        text="Close Tab"
        variant="outlined"
        onClick={handleActionSheetIsOpen}
      />
      <TabActionSheet
        isActionSheetOpen={isActionSheetOpen}
        onActionSheetOpen={handleActionSheetIsOpen}
        onPerformAction={() => {
          void handleCloseEmptyTab();
        }}
        title="Are you sure?"
        message="No items have been added to your tab. Please confirm you would like to close it."
        actionText="Confirm"
        isActionLoading={isLoading}
      />
    </>
  );
}

export default EmptyTab;
