import { ReactNode, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import ComponentLoader from "@component/Loading/ComponentLoader";
import getNextScreen from "@utils/getNextScreen";
import { isDev } from "@utils/constants";

const TabInitialRouteWrapper = ({ children }: { children?: ReactNode }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [initialRoute, setInitialRoute] = useState<string | undefined>();
  const [shouldRedirect, setShouldRedirect] = useState(true);

  const { placeCode } = useParams();
  const baseUrl = `/venue/${placeCode}`;
  const navigate = useNavigate();

  const location = useLocation();
  const pathname = location.pathname;
  const qaRegex = /^\/qa(\/.*)?$/;
  const isQAPage = isDev && qaRegex.test(pathname);

  const xRooamSession = localStorage.getItem("x-rooam-session");

  useEffect(() => {
    if (!xRooamSession && !isQAPage) {
      setInitialRoute(`${baseUrl}/phone`);
    } else if (!initialRoute && !isQAPage) {
      getNextScreen()
        .then((initialPath) => {
          setInitialRoute(`${baseUrl}/${initialPath}`);
        })
        .catch((error) => {
          console.error(error);
          setInitialRoute(`${baseUrl}/phone`);
        });
    }
  }, [baseUrl, initialRoute, isQAPage, xRooamSession]);

  useEffect(() => {
    if (initialRoute && shouldRedirect && !isQAPage) {
      setIsLoading(false);
      setShouldRedirect(false);
      navigate(initialRoute);
    }
  }, [initialRoute, isQAPage, navigate, shouldRedirect]);

  if (isLoading) {
    <ComponentLoader />;
  }

  return <>{children}</>;
};

export default TabInitialRouteWrapper;
