import * as Styled from "./VerifyCode.styles";

import { ChangeEvent, useCallback, useState } from "react";

import Button from "@component/Button/Button";
import { addBreadcrumb } from "@sentry/react";
import formatUSPhoneNumber from "@utils/formatUSPhoneNumber";
import { isDev } from "@utils/constants";
import { useAuth } from "@context/AuthContext";
import { useNavigate } from "react-router-dom";
import { useTab } from "@context/TabContext";
import verifyCodeAndLogin from "@utils/verifyCodeAndLogin";

function VerifyCode() {
  const [isLoading, setIsLoading] = useState<boolean>();
  const [validationCode, setValidationCode] = useState<string | undefined>(
    isDev ? "670-114" : undefined
  );
  const [validationError, setValidationError] = useState<string | undefined>();
  const [resendText, setResendText] = useState<string>("Send a new code");

  const { getWebTabPinCode, webTabSession } = useAuth();

  const navigate = useNavigate();
  const { placeCode } = useTab();

  const phoneNumber = webTabSession?.phoneNumber;
  const formattedPhoneNumber = formatUSPhoneNumber(phoneNumber);

  const handleTextFieldChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValidationCode(event.target.value);
      setValidationError(undefined);
    },
    []
  );

  const handleValidateCode = useCallback(async () => {
    setIsLoading(true);

    if (phoneNumber && validationCode && placeCode) {
      addBreadcrumb({
        level: "info",
        category: "user_action",
        message: `WebTab Login at Place ${placeCode}`,
      });

      const code = validationCode.replace(/-/g, "");

      const response = await verifyCodeAndLogin({
        number: phoneNumber,
        code,
        placeCode,
      });

      if (!response) {
        setValidationError("An error ocurred. Please try again.");
        setIsLoading(false);
        throw new Error(`handleValidateCode > Request failed: ${response}`);
      }

      return navigate(`/venue/${placeCode}/${response}`, {
        replace: true,
      });
    }

    setIsLoading(false);
  }, [navigate, phoneNumber, placeCode, validationCode]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        void handleValidateCode();
      }
    },
    [handleValidateCode]
  );

  const handleResendPinCode = useCallback(async () => {
    if (phoneNumber) {
      addBreadcrumb({
        level: "info",
        category: "user_action",
        message: `WebTab Pin Code resend at Place ${placeCode}`,
      });

      setValidationError(undefined);

      const response = await getWebTabPinCode(phoneNumber, true);

      if (response !== "success") {
        setValidationError("An error ocurred. Please try again.");
        throw new Error(`handleResendPinCode > Request failed: ${response}`);
      }

      setResendText("Code sent");

      return setTimeout(() => {
        setResendText("Send a new code");
      }, 20000);
    }
  }, [getWebTabPinCode, phoneNumber, placeCode]);

  return (
    <div>
      <Styled.Title>Enter the Code</Styled.Title>
      <span>Sent to {formattedPhoneNumber}</span>

      <Styled.Container direction="column">
        <Styled.InputContainer>
          <Styled.Input
            mask={[/\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/]}
            className="form-control"
            placeholder="Enter PIN Code"
            guide={false}
            id="verify-code"
            onBlur={() => {}}
            onChange={(e) => handleTextFieldChange(e)}
            onKeyDown={handleKeyDown}
            value={validationCode}
          />
        </Styled.InputContainer>

        {validationError && (
          <Styled.ErrorMessage>{validationError}</Styled.ErrorMessage>
        )}

        <Button
          color="secondary"
          isLoading={isLoading}
          disabled={!validationCode}
          onClick={() => {
            void handleValidateCode();
          }}
          text="Next"
        />

        <Styled.ResendCode>
          <span>Problems with your auth-code?</span>
          <Button
            className={resendText === "Code sent" ? "sent" : ""}
            disabled={resendText === "Code sent"}
            text={resendText}
            onClick={() => {
              void handleResendPinCode();
            }}
            variant="text"
            size="small"
          />
        </Styled.ResendCode>
      </Styled.Container>
    </div>
  );
}

export default VerifyCode;
