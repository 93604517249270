import {
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { PaymentMethodProps } from "@component/TabPaymentMethodList/TabPaymentMethodList";
import { TipProps } from "@utils/getTabTips";
import apiClient from "../utils/api/apiClient";
import { environment } from "@utils/constants";
import getPinCode from "@utils/getPinCode";
import useAuthToken from "../hooks/useAuthToken";
import useTabAuth from "@hooks/useTabAuth";

export interface UserProps {
  firstName?: string;
  lastName?: string;
  profileImageUrl?: string;
}

export interface WebTabSessionProps {
  phoneNumber: string | undefined;
  xRooamSession: string | null;
  isSessionOpen: boolean;
  tabData:
    | {
        tabNumber?: string;
        tip?: TipProps;
      }
    | undefined;
  selectedPaymentMethod?: PaymentMethodProps;
}

interface AuthContextProps {
  didSignOut: () => void;
  isAuthenticated: boolean;
  user?: UserProps;
  getWebTabPinCode: (
    phoneNumber: string,
    resend?: boolean
  ) => Promise<"success" | "failed">;
  webTabSession?: WebTabSessionProps | undefined;
  setWebTabSession?: React.Dispatch<React.SetStateAction<WebTabSessionProps>>;
}

export const AuthContext = createContext<AuthContextProps | null>(null);

AuthContext.displayName = "AuthContext";

function AuthProvider(props: any): ReactElement {
  const authToken = useAuthToken();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<UserProps>();
  const [webTabSession, setWebTabSession] = useState<WebTabSessionProps>({
    phoneNumber: localStorage.getItem("phoneNumber") ?? undefined,
    xRooamSession: localStorage.getItem("x-rooam-session"),
    isSessionOpen: false,
    tabData: {
      tabNumber: undefined,
      tip: undefined,
    },
    selectedPaymentMethod: undefined,
  });

  const xRooamSession = useTabAuth();

  const isWebTab = environment.isWebTab;

  // determine if user is authenticated
  useEffect(() => {
    setIsAuthenticated(Boolean(authToken));
  }, [authToken]);

  useEffect(() => {
    if (isAuthenticated && authToken && !isWebTab) {
      fetchUserData(authToken)
        .then((data) => {
          setUser(data);
        })
        .catch((error) =>
          console.error("Auth Context > Error getting User data", error)
        );
    }
  }, [authToken, isAuthenticated, isWebTab]);

  // determine if it's a Web Tab Session
  useEffect(() => {
    if (isWebTab) {
      setIsAuthenticated(Boolean(xRooamSession));
    }
  }, [isWebTab, xRooamSession]);

  useEffect(() => {
    if (isAuthenticated && xRooamSession) {
      setWebTabSession({
        ...webTabSession,
        xRooamSession,
        isSessionOpen: true,
      });
    }
    // 🚨 Don't add webTabSession to the dependencies array or it will trigger an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, xRooamSession]);

  const signOut = useCallback(() => {
    setIsAuthenticated(false);
  }, []);

  // Send PIN Code to Web Tab user and get Session
  const getWebTabPinCode = useCallback(
    async (phoneNumber: string, resend?: boolean) => {
      const session = await getPinCode({ number: phoneNumber, resend });

      if (session) {
        localStorage.setItem("x-rooam-session", session);
        localStorage.setItem("phoneNumber", phoneNumber);

        setWebTabSession({
          ...webTabSession,
          xRooamSession: session,
          phoneNumber,
        });

        return "success";
      }

      return "failed";
    },
    [webTabSession]
  );

  // This is the returned value available through the provider.
  // Add every prop/function that should be returned here
  const value: AuthContextProps = useMemo(
    () => ({
      didSignOut: signOut,
      getWebTabPinCode,
      isAuthenticated,
      user,
      webTabSession,
      setWebTabSession,
    }),
    [getWebTabPinCode, isAuthenticated, signOut, user, webTabSession]
  );

  return (<AuthContext.Provider value={value} {...props} />) as ReactElement;
}

// Hook to consume AuthProvider
export function useAuth(): AuthContextProps {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }

  if (context === null) {
    throw new Error("AuthProvider supplied null context");
  }

  return context;
}

export default AuthProvider;

// Extras
const fetchUserData = async (authToken: string): Promise<UserProps> => {
  return await apiClient.get({
    path: "/user",
    headers: { resyAuthToken: authToken },
  });
};
