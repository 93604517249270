import * as Styled from "./BraintreeField.styles";

import { FormHelperText } from "@mui/material";

interface BraintreeFieldProps {
  error: boolean;
  errorText: string;
  id: string;
  label: string;
}

function BraintreeField({
  error,
  errorText,
  id,
  label,
}: BraintreeFieldProps): React.ReactElement {
  return (
    <Styled.FormControl error={error}>
      <label htmlFor={id} data-testid={id}>
        {label}
      </label>
      <div
        id={id}
        className={error ? "braintree-hosted-fields-invalid" : ""}
      ></div>
      {error && errorText && (
        <FormHelperText className="error-text">{errorText}</FormHelperText>
      )}
    </Styled.FormControl>
  );
}

export default BraintreeField;
