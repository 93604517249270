import { CheckIconContainer } from "@component/PaymentComplete/PaymentComplete.styles";
import StyledContainer from "@GlobalComponents/StyledContainer";
import { colors } from "@theme/Themes";
import styled from "styled-components";

export const Container = styled(StyledContainer)`
  gap: 16px;
  margin: 32px 0 0;

  button {
    > div {
      display: flex;
      gap: 8px;
    }
  }
`;

export const IconOuterContainer = styled(StyledContainer)`
  align-items: center;
  text-align: center;

  p {
    margin: 0;
  }
`;

export const IconContainer = styled(CheckIconContainer)`
  background-color: ${colors.errorDark};
  text-align: center;

  &:before {
    background-color: ${colors.error};
    opacity: 0.6;
  }

  svg {
    fill: #ffffff;
  }
`;
