import StyledContainer from "@GlobalComponents/StyledContainer";
import { colors } from "@theme/Themes";
import styled from "styled-components";

interface ContainerProps {
  colorTheme: {
    backgroundColor: string;
    borderColor: string;
  };
}

export const Container = styled(StyledContainer)<ContainerProps>`
  background-color: ${({ colorTheme }) =>
    colorTheme.backgroundColor ? colorTheme.backgroundColor : ""};
  border: 1px solid;
  border-color: ${({ colorTheme }) =>
    colorTheme.borderColor ? colorTheme.borderColor : colors.border};
  border-radius: 6px;
  font-size: 1.2rem;
  margin-top: 16px;
  gap: 14px;
  padding: 10px 14px;
`;

export const BannerImage = styled.img`
  width: 80px;
`;
