import { Avatar, Paper, Stack } from "@mui/material";

import { colors } from "@theme/Themes";
import styled from "styled-components";

type RGBA = `rgba(${number}, ${number}, ${number}, ${number})`;

interface GradientColor {
  start: RGBA;
  startOpaque: RGBA;
  stop: RGBA;
  stopOpaque: RGBA;
}

const opacity = 0.3;

const defaultColor: GradientColor = {
  start: "rgba(93, 75, 218, 1.0)",
  startOpaque: `rgba(93, 75, 218, ${opacity})`,
  stop: "rgba(102, 137, 232, 1.0)",
  stopOpaque: `rgba(102, 137, 232, ${opacity})`,
};

const itemColorGradients: GradientColor[] = [
  defaultColor,
  {
    start: "rgba(93, 0, 184, 1.0)",
    startOpaque: `rgba(93, 0, 184, ${opacity})`,
    stop: "rgba(151, 20, 152, 1.0)",
    stopOpaque: `rgba(151, 20, 152, ${opacity})`,
  },
  {
    start: "rgba(169, 47, 169, 1.0)",
    startOpaque: `rgba(169, 47, 169, ${opacity})`,
    stop: "rgba(220, 46, 132, 1.0)",
    stopOpaque: `rgba(220, 46, 132, ${opacity})`,
  },
  {
    start: "rgba(235, 69, 92, 1.0)",
    startOpaque: `rgba(235, 69, 92, ${opacity})`,
    stop: "rgba(245, 112, 56, 1.0)",
    stopOpaque: `rgba(245, 112, 56, ${opacity})`,
  },
  {
    start: "rgba(243, 92, 0, 1.0)",
    startOpaque: `rgba(243, 92, 0, ${opacity})`,
    stop: "rgba(252, 163, 0, 1.0)",
    stopOpaque: `rgba(252, 163, 0, ${opacity})`,
  },
];

export const ListContainer = styled(Stack)`
  margin-bottom: 0;

  .MuiCollapse-root {
    &:nth-child(5n + 1) {
      .MuiPaper-root {
        > .MuiAvatar-root {
          background-image: linear-gradient(
            45deg,
            ${() => (itemColorGradients[0] ?? defaultColor).startOpaque} 0%,
            ${() => (itemColorGradients[0] ?? defaultColor).stopOpaque} 100%
          );

          .MuiAvatar-root {
            background-image: linear-gradient(
              45deg,
              ${() => (itemColorGradients[0] ?? defaultColor).start} 0%,
              ${() => (itemColorGradients[0] ?? defaultColor).stop} 100%
            );
          }
        }
      }
    }

    &:nth-child(5n + 2) {
      .MuiPaper-root {
        > .MuiAvatar-root {
          background-image: linear-gradient(
            45deg,
            ${() => (itemColorGradients[1] ?? defaultColor).startOpaque} 0%,
            ${() => (itemColorGradients[1] ?? defaultColor).stopOpaque} 100%
          );

          .MuiAvatar-root {
            background-image: linear-gradient(
              45deg,
              ${() => (itemColorGradients[1] ?? defaultColor).start} 0%,
              ${() => (itemColorGradients[1] ?? defaultColor).stop} 100%
            );
          }
        }
      }
    }

    &:nth-child(5n + 3) {
      .MuiPaper-root {
        > .MuiAvatar-root {
          background-image: linear-gradient(
            45deg,
            ${() => (itemColorGradients[2] ?? defaultColor).startOpaque} 0%,
            ${() => (itemColorGradients[2] ?? defaultColor).stopOpaque} 100%
          );

          .MuiAvatar-root {
            background-image: linear-gradient(
              45deg,
              ${() => (itemColorGradients[2] ?? defaultColor).start} 0%,
              ${() => (itemColorGradients[2] ?? defaultColor).stop} 100%
            );
          }
        }
      }
    }

    &:nth-child(5n + 4) {
      .MuiPaper-root {
        > .MuiAvatar-root {
          background-image: linear-gradient(
            45deg,
            ${() => (itemColorGradients[3] ?? defaultColor).startOpaque} 0%,
            ${() => (itemColorGradients[3] ?? defaultColor).stopOpaque} 100%
          );

          .MuiAvatar-root {
            background-image: linear-gradient(
              45deg,
              ${() => (itemColorGradients[3] ?? defaultColor).start} 0%,
              ${() => (itemColorGradients[3] ?? defaultColor).stop} 100%
            );
          }
        }
      }
    }

    &:nth-child(5n + 5) {
      .MuiPaper-root {
        > .MuiAvatar-root {
          background-image: linear-gradient(
            45deg,
            ${() => (itemColorGradients[4] ?? defaultColor).startOpaque} 0%,
            ${() => (itemColorGradients[4] ?? defaultColor).stopOpaque} 100%
          );

          .MuiAvatar-root {
            background-image: linear-gradient(
              45deg,
              ${() => (itemColorGradients[4] ?? defaultColor).start} 0%,
              ${() => (itemColorGradients[4] ?? defaultColor).stop} 100%
            );
          }
        }
      }
    }
  }
`;

export const Item = styled(Paper)`
  align-items: center;
  border: 1px solid ${colors.borderLight};
  box-shadow: 0 1px 6px 1px rgb(0 0 0 / 9%) !important;
  display: flex;
  font-size: 1.4rem;
  gap: 12px;
  margin: 4px 0;
  padding: 13px 17px 13px 18px;
`;

interface ItemCountProps {
  index: number;
}

export const ItemCount = styled(Avatar)<ItemCountProps>`
  display: flex;
  width: 52px !important;
  height: 52px !important;
  background-color: transparent !important;

  .MuiAvatar-root {
    width: 41px !important;
    height: 41px !important;
    font-weight: 600;
    font-size: 1.9rem;
    color: #ffffff;
    background-color: transparent !important;
  }
`;

export const ItemInfo = styled.div`
  display: flex;
  flex: 100% 1 1;
  flex-direction: column;
`;

export const ItemName = styled.span`
  color: ${colors.text};
  font-weight: 500;
`;

export const ItemUnitPrice = styled.span`
  color: ${colors.textSecondary};

  .item-discount {
    svg {
      fill: ${colors.success};
      transform: rotateY(180deg);
    }
  }
`;

export const ItemPrice = styled.div`
  color: ${colors.text};
  display: flex;
  font-family: inherit;
  font-weight: 500;
  justify-self: flex-end;
`;
