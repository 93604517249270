import * as Styled from "./ExpressCheckout.styles";

import React, { useCallback, useEffect, useState } from "react";

import ApplePayToken from "./ApplePayToken";
import { Client } from "braintree-web";
import Error from "@component/Error/Error";
import { ErrorType } from "@component/PaymentDetails/PaymentDetails";
import GooglePayToken from "./GooglePayToken";

interface ExpressCheckoutWebTabProps {
  clientInstance: Client | undefined;
  onAddPaymentMethod?: (
    nonce: braintree.ApplePayPayload["nonce"],
    paymentType: braintree.ApplePayPayload["type"]
  ) => void;
}

function ExpressCheckoutWebTab({
  clientInstance,
  onAddPaymentMethod,
}: ExpressCheckoutWebTabProps): React.ReactElement {
  const [supportsApplePay, setSupportsApplePay] = useState<boolean>(false);
  const [error, setError] = useState<ErrorType | undefined>();
  const [errorMessage, setErrorMessage] = useState<
    React.ReactNode | undefined
  >();

  useEffect(() => {
    if (
      window.ApplePaySession &&
      ApplePaySession.supportsVersion(3) &&
      ApplePaySession.canMakePayments()
    ) {
      setSupportsApplePay(true);
    }
  }, [supportsApplePay]);

  const handleError = useCallback((errorNode: React.ReactNode) => {
    setErrorMessage(errorNode);
  }, []);

  return (
    <Styled.Container isWebTab={true}>
      {error && errorMessage && <Error>{errorMessage}</Error>}

      {supportsApplePay && onAddPaymentMethod && (
        <ApplePayToken
          clientInstance={clientInstance}
          onSuccess={onAddPaymentMethod}
          onError={handleError}
          setError={setError}
        />
      )}

      {onAddPaymentMethod && (
        <GooglePayToken
          clientInstance={clientInstance}
          onSuccess={onAddPaymentMethod}
          onError={handleError}
          setError={setError}
        />
      )}
    </Styled.Container>
  );
}

export default ExpressCheckoutWebTab;
