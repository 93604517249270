import * as Styled from "./VendorFooter.styles";

import Button from "@component/Button/Button";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import VendorButtons from "./VendorButtons";
import VendorLogo from "@vendor/components/VendorLogo/VendorLogo";
import { useCallback } from "react";
import { useLocation } from "react-router-dom";

export default function VendorFooter(): React.ReactElement {
  const year = new Date().getFullYear();

  const location = useLocation();
  const { pathname } = location;

  const handleGiveFeedback = useCallback(() => {
    window.open("mailto:help@rooam.co", "blank");
  }, []);

  const handleAboutRooam = useCallback(() => {
    window.open("https://rooam.co", "blank");
  }, []);

  return (
    <>
      {!pathname.includes("/complete") && (
        <Styled.HelpButton
          node={
            <>
              <HelpOutlineIcon color="disabled" fontSize="large" />{" "}
              <span>Need help?</span>
            </>
          }
          variant="text"
        />
      )}
      <VendorButtons />
      <Styled.Logo href="https://rooam.co/" target="_blank" rel="noreferrer">
        Powered by
        <VendorLogo />
      </Styled.Logo>
      <Styled.FooterButtons>
        <Button
          onClick={handleGiveFeedback}
          text="Give feedback"
          size="medium"
          variant="outlined"
        />
        <Button
          onClick={handleAboutRooam}
          text="About Rooam"
          size="medium"
          variant="outlined"
        />
      </Styled.FooterButtons>
      <span className="text-lighter">© Copyright {year} - Rooam, Inc.</span>
    </>
  );
}
