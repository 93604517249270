import Loader from "components/Loader/Loader";
import StyledContainer from "@GlobalComponents/StyledContainer";
import styled from "styled-components";

const Container = styled(StyledContainer)`
  align-items: center;
  height: 50vh;
  justify-content: center;
`;

function ComponentLoader() {
  return (
    <Container>
      <Loader />
    </Container>
  );
}

export default ComponentLoader;
