import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

import { SummaryProps } from "./Summary";
import { colors } from "@theme/Themes";
import styled from "styled-components";

export const AccordionContainer = styled(Accordion)`
  &.overwrite {
    background: transparent;
    box-shadow: initial;
    flex: 100% 1 1;
    font-size: 1.5rem;
    padding: 0;
    position: relative;

    /* The summary located at the top */
    &#summary-accordion {
      margin-top: 0;

      .MuiAccordionSummary-root {
        background-color: ${colors.background};
      }

      > .MuiAccordionSummary-root {
        border-top: 1px solid ${colors.border};
        position: relative;

        /*  Create bottom border */
        &:before {
          background-color: ${colors.border};
          content: "";
          height: 1px;
          left: 50%;
          position: absolute;
          bottom: 0px;
          transform: translateX(-50%);
          width: 150%;
          z-index: 1;
        }
      }

      .MuiAccordionDetails-root {
        background-color: ${colors.background};
      }
    }

    &:after {
      background: ${colors.background};
      border-bottom: 1px solid ${colors.border};
      border-top: 1px solid ${colors.border};
      content: " " !important;
      height: 100%;
      left: -50%;
      position: absolute;
      top: 0;
      width: calc(200%);
      z-index: -1;
    }

    > div:not(:first-child) {
      margin: 0;
    }

    > * {
      z-index: 1;
    }
  }

  &.Mui-disabled {
    background-color: transparent !important;

    > * {
      opacity: 1 !important;
    }
  }

  &.inner-accordion {
    align-items: center;
    background-color: transparent;
    box-shadow: initial;
    flex: 100% 1 1;
    font-size: 1.5rem;

    .MuiAccordionSummary-root {
      background-color: ${colors.lighterBackground};
      min-height: initial;
      padding: 0;

      > div {
        display: flex;
        flex: 100% 1 1;
        font-weight: 400;
        margin: 0;

        &:last-child {
          justify-content: flex-end;
          flex: 40% 1 1;
        }
      }
    }

    .MuiAccordionDetails-root {
      background-color: ${colors.lighterBackground};
      padding: 10px 0 0;

      > div {
        padding-left: 0;
        padding-right: 0;

        span {
          color: ${colors.textDisabled};
          font-size: 13px;
          font-weight: 400;
          line-height: 18px;
        }
      }
    }
  }
`;

interface TitleProps {
  collapsible?: string;
}

export const Title = styled(AccordionSummary)<TitleProps>`
  &.overwrite {
    flex: 100% 1 1;
    font-weight: 500;
    padding: 0 ${({ collapsible }) => (collapsible === "true" ? "0" : "18px")};

    &.Mui-expanded {
      min-height: initial;
    }

    > div {
      align-items: center;
      display: block;
      line-height: 22px;
      margin: 18px 0;

      > div {
        display: flex;
        width: 100%;

        > span {
          align-items: center;
          display: flex;
          width: 100%;
          gap: 8px;

          &:last-child {
            justify-content: flex-end;
            flex: 40% 1 1;
          }
        }
      }
    }

    &.Mui-expanded {
      svg {
        transform: rotateZ(180deg);
      }
    }

    svg {
      fill: ${colors.textDisabled};
      height: 17px;
      margin-top: 2px;
      transition: transform 0.2s ease-in-out;
      width: 17px;
    }
  }
`;

export const Details = styled(AccordionDetails)`
  &.overwrite {
    padding: 16px 0 !important;
    position: relative;

    &:after {
      background-color: ${colors.border};
      content: "";
      height: 1px;
      left: 50%;
      position: absolute;
      bottom: 0;
      transform: translateX(-50%);
      width: 150%;
      z-index: 1;
    }
  }
`;

export const Grid = styled.div<SummaryProps>`
  display: grid;
  grid-template-columns: 2fr 1fr;
  line-height: 22px;
  padding-top: 18px;
  width: 100%;

  ${({ collapsible }) => {
    if (collapsible === true) {
      return `
      background: ${colors.background};
      border-bottom: 1px solid ${colors.border};
      border-top: 1px solid ${colors.border};
      
      &:before, &:after {
        content: '';
      }
    `;
    }
  }}
`;

export const RowContainer = styled.div`
  flex: 100% 1 1;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex: 100% 1 1;
  padding: 3px 0;

  &.check {
    border-bottom: 1px solid ${colors.border};
    padding-bottom: 13px;
    margin-bottom: 6px;
  }

  &.footer-row {
    padding: 13px 0;
  }

  &.total-row {
    font-weight: 500;
    padding: 18px 0;

    > div {
      color: ${colors.text};

      &:first-child {
        font-size: 1.6rem;
      }
    }
  }

  .discount {
    .discount-amount {
      color: ${colors.success};
    }
  }
`;

export const Column = styled.div`
  align-items: center;
  color: ${colors.textSecondary};
  display: flex;
  flex: 100% 1 1;
  font-size: 1.5rem;
  gap: 6px;

  &:last-child {
    justify-content: flex-end;
    flex: 100px 1 1;
  }

  span {
    display: flex;
    gap: 6px;
    align-items: center;
  }

  svg {
    fill: ${colors.textDisabled};
    height: 15px;
    transition: transform 0.2s ease-in-out;
    width: 15px;
  }

  .discount-row > span {
    display: inline-block;
    margin-right: 4px;
  }

  .discount {
    color: ${colors.success};
  }
`;

export const SelectedItemsContainer = styled(Row)`
  background-color: ${colors.background};
  display: flex;
  flex-direction: column;
  padding: 8px !important;

  > div {
    padding: 0;
  }

  .item-quantity,
  .total-by-item {
    font-size: 1.4rem;
  }
`;

export const Footer = styled(Row)<SummaryProps>`
  flex-direction: column;
  margin: 18px 0 0;
  margin-top: ${({ collapsible }) => collapsible === true && 0};
  padding: 0;
  position: relative;
  width: 100%;

  > div {
    color: ${colors.text};
  }

  ${({ collapsible }) => {
    if (!collapsible) {
      return `
        background: ${colors.background};
        border-bottom: 1px solid ${colors.border};
        border-top: 1px solid ${colors.border};
        
        &:before, &:after {
          content: '';
        }

        .footer-row:not(last-child) {
          position: relative;

          &:after {
            content: '';
            border-bottom: 1px solid ${colors.border};
            width: calc(150%);
            position: absolute;
            bottom: 0px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      `;
    }
  }}

  &:before,
  &:after {
    background: ${colors.background};
    border-bottom: 1px solid ${colors.border};
    border-top: 1px solid ${colors.border};
    height: calc(100% + 2px);
    left: calc(-20% + 1px);
    position: absolute;
    top: -1px;
    width: 20%;
    z-index: -1;
  }

  &:after {
    left: initial;
    right: calc(-20% + 1px);
  }

  > div {
    &:last-child {
      justify-content: end;
    }
  }
`;
